import React from 'react';
const AddButton = props =>{

    return (
        <button onClick={props.onclick} type="text"
                className={`disabled:opacity-70 bg-add_btn rounded-md py-3 px-4 text-white flex items-center justify-center ${props.className}`} disabled={props.disabled}>
            {!props.withoutIcon && <img className="mr-2" src="/icons/add.svg" alt=""/>}
            <div>{props.btn_text}</div>
        </button>
    );
}
export default AddButton;