import {t} from "i18next";

const SurveyActions = (props) => {
    return (
        <>
            <div className="mb-4">
                <div className="mt-4 text-sm text-gray-600">
                    <div className="mb-2">{t("FilterByLanguage")}</div>
                    <div className="flex items-center justify-between">
                        <div className="flex items-center">
                            <div className="relative w-width_ex mr-3">
                                <select onChange={props.onchange} className=" classic-input bg-transparent py-3" name="" id="">
                                    <option value="fr">{t("French")}</option>
                                    <option value="en">{t("English")}</option>
                                    <option value="es">{t("Spanish")}</option>
                                    <option value="ru">{t("Russian")}</option>
                                </select>
                                <img className="absolute right-2 top-5" src="/icons/arrow-t-down.svg" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SurveyActions;
