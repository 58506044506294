import React from "react";
import {helpers} from "../../utils/helpers/helpers";
import variables from "../../constants/variables";
import {t} from "i18next";
import {Link} from "react-router-dom";

const QuestionsWidget = (props) => {
    const changeColor = (e)=>{
        if(props.select){
            let select = e.target.querySelector(".item-select");
            if(select){
                select.classList.toggle('bg-gray-300');
                select.classList.toggle('bg-blue-400');
                select.classList.toggle('assigned');
                const item = e.target.closest('.question-item');
                if(item){
                    item.classList.toggle('border-gray-300');
                    item.classList.toggle('border-blue-400');
                }
            }

        }
    }
    const {transformDate} = helpers();
    return (
        <>
            {props.items.map((item, k) =>
                <div key={item._id}  onClick={(e)=>{changeColor(e)}} className={`${props.select ? "cursor-pointer" : ""}  my-2 ${props.border ? (item.total_score ? 'border border-green-500' : 'border border-delete_bg') : ''}
                 ${props.select ? 'border border-gray-300' : ''}
                 bg-card_bg rounded-md p-3 flex items-center justify-between question-item`}>
                    <div className="w-9/10">
                        <div className="flex-center">
                            <div className={`${props.select ? "block" : "hidden"}`}>
                                <div style={{"width": "17px", "height": "17px"}}
                                     data-question_id={item._id}
                                     className="item-select mr-4 rounded-full bg-gray-300 cursor-pointer">
                                </div>
                            </div>
                            {/*{props.subclient_id && item.question_id ? <div>{k+1}</div> : ''}*/}
                            <div>
                                <div className="text-sm text-red-400">{t("Language")}: {item.question_id ? variables.langName[item.question_id.survey_info.language] : (item.survey_info ? variables.langName[item.survey_info.language] : '')}</div>
                                <div className="text-gray-600 font-bold lg:text-base text-sm mr-1">{item.question_id ? item.question_id.survey_info.name : (item.survey_info ? item.survey_info.name : '')}</div>
                            </div>
                        </div>
                    </div>
                    <div className="w-1/10 flex-center">
                        {props.deleteButtonHandler && (
                            <button onClick={(event)=>{props.deleteButtonHandler(item._id)}}
                                    type="text" className="flex items-center justify-center mr-6 text-delete_bg text-sm">
                                <img className=" mr-2" src="/icons/remove-circle-red.svg" alt=""/>
                                {t("RemoveSurvey")}
                            </button>
                        )}
                        {item.survey_id ? <div className="flex-center">
                            <div>
                                <div className="text-sm text-gray-500">{t("SurveyStatus")}</div>
                                <div className={`${item.status_complete ? "text-green-400": "text-delete_bg"} font-bold text-md`}>{item.status_complete ? t("Completed") : t("NotCompleted")}</div>
                            </div>
                            <div className="w-16 flex-center ">
                                <div className={`"${item.score ? "bg-green-500": ""} rounded-md font-bold text-center px-3 py-1 text-white"`}>{item.score}</div>
                            </div>
                        </div> : ""}
                        {item.assigned && <div className="mr-5 text-sm text-white py-1 px-5 rounded-3xl bg-blue-500">Already Assigned</div>}
                        <Link to={`/questions/answer/`+item._id}>
                            <img className="mr-4" src="/icons/arrow-right.svg" alt=""/>
                        </Link>
                    </div>
                </div>
            )}
        </>
    )
}

export default QuestionsWidget;
