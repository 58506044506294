import React, {useEffect, useState} from 'react';
import Layout from "../../layouts/Layout";
import LoadingPageSpinner from "../../components/Loading/LoadingPageSpinner";
import {surveyApi} from "./api/api";
import SurveyActions from "../../components/Survey/SurveyActions";
import SurveyWidget from "../../components/Survey/SurveyWidget";
import Alert from "../../components/Alert/Alert";
import {t} from "i18next";

const Surveys = () => {
    const { getSurveysRequest, isLoading,  error } = surveyApi();
    const [loadedSurveys, setLoadedSurveys] = useState([]);
    const [lang, setLang] = useState('fr');
    useEffect(() => {
        const fetchSurveys = async () => {
            try {
                const responseData = await getSurveysRequest(lang);
                const items = responseData.data;
                if(items && items.length){
                    setLoadedSurveys(items);
                }else{
                    setLoadedSurveys([]);
                }
            } catch (err) {
                console.log(err);
            }
        };
        fetchSurveys();
    }, [lang]);
    return (
        <Layout>
            <Alert error={error}/>
            {isLoading && (<LoadingPageSpinner/>)}
            <div className="p-4">
            <SurveyActions onchange={(e) => setLang(e.target.value)}/>
            </div>
            {loadedSurveys.length ? (
            <div className="p-4">
                <SurveyWidget items={loadedSurveys}/>
            </div>) : <div className="text-center text-gray-500 mt-4">{t('SurveysNotFound')}</div>}
        </Layout>);
};
export default Surveys;
