import React, {useEffect, useState} from 'react';
import Layout from "../../layouts/Layout";
import {documentsApi} from "./api/api";
import {usePagination} from "../../hooks/pagination-hook";
import LoadingPageSpinner from "../../components/Loading/LoadingPageSpinner";
import DocumentWidget from "../../components/Documents/DocumentWidget";
import Modal from "../../components/Dialog/Modal";
import {t} from "i18next";
import {useForm} from "../../hooks/form-hook";
import Alert from "../../components/Alert/Alert";

const Documents = () => {
    const { getSubClientAssignedDocumentsRequest, getDocumentsRequest, deleteDocumentsRequest, uploadDocumentsRequest, downloadDocumentRequest, isLoading,  error, clearError } = documentsApi();
    const {currentPage, setCurrentPage, pageCount, setCountHandler} = usePagination();
    const [loadedDocuments, setLoadedDocuments] = useState([]);
    const [search, setSearch] = useState();
    const [deleteId, setDeleteId] = useState(false);
    const [updated, setUpdated] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showUploadDialog, setShowUploadDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [message, setMessage] = useState("");

    useEffect(() => {
        const fetchDocuments = async () => {
            try {
                const responseData = await getSubClientAssignedDocumentsRequest();
                const items = responseData.data.result;
                // if(items && items.length){
                    setLoadedDocuments(items);
                // }
                // setCountHandler(responseData.total, variables.maxDocumentsPagination);
            } catch (err) {
                console.log(err);
            }
        };
        fetchDocuments();
    }, [currentPage, search, updated]);

    const deleteDocumentHandler = async event => {

        console.log(deleteId);
        event.preventDefault();

        try {
            setShowDeleteDialog(false);
            await deleteDocumentsRequest(deleteId);
            setUpdated(prevMode => !prevMode);

        } catch (err) {
        }
    }
    const deleteButtonHandler = (id)=>{
        setDeleteId(id);
        setShowDeleteDialog(true);
    }

    const downloadHandler = async (_id) => {

        try{
            const response = await downloadDocumentRequest(_id);
            const url = response.data;
            window.open(
                url,
                '_blank' // <- This is what makes it open in a new window.
            );
        }catch (e){}

    }
    //
    const addDocumentButtonHandler = () => {
        setShowUploadDialog(true);
    }
    const [formState, inputHandler] = useForm(
        {
            file: {
                value: null,
                isValid: false
            }
        },
        false
    );

    return (
        <Layout>
            {isLoading && (<LoadingPageSpinner/>)}
            <div className="absolute z-50 flex-center w-full">
                <Alert error={errorMessage || error} message={message}/>
            </div>
            <Modal
                show={showDeleteDialog}
                className={"dialog bg-dialog_bg w-width_smx"}
                onCancel={()=>{setShowDeleteDialog(false)}}
                headerClass={"dialog-header text-lg text-red-500"}
                header={t("DocumentDeleting")}
                contentClass="p-4"
                footer={
                    <div className="p-4">
                        <div className="w-full cancel-btn mb-2" onClick={()=>{setShowDeleteDialog(false)}}>{t("Cancel")}</div>
                        <div className="w-full delete-btn" onClick={deleteDocumentHandler}>{t("Delete")}</div>
                    </div>
                }
            >
                <div className="w-full text-gray-600 text-lg my-6">
                    {t("DeleteDocument")}
                </div>
            </Modal>
            {/*<Modal*/}
            {/*    show={showUploadDialog}*/}
            {/*    className={"dialog bg-dialog_bg w-width_mx"}*/}
            {/*    onCancel={()=>{setShowUploadDialog(false)}}*/}
            {/*    headerClass={"dialog-header text-lg text-gray-600"}*/}
            {/*    header={t("DocumentUploading")}*/}
            {/*    contentClass="px-4 pb-4"*/}
            {/*    footer={*/}
            {/*        <div className="p-4 flex">*/}
            {/*            <div className="w-1/2 cancel-btn" onClick={()=>{setShowUploadDialog(false)}}>{t("Cancel")}</div>*/}
            {/*            /!*<AddButton className={"w-full"} btn_text={t("UploadDocument")} onClick={uploadDocumentHandler}/>*!/*/}
            {/*            <div onClick={uploadDocumentHandler} className="w-1/2 mx-1 submit-btn">{t("AddDocuments")}</div>*/}
            {/*        </div>*/}
            {/*    }*/}
            {/*>*/}
            {/*    <div className="w-full text-gray-600 text-lg my-2">*/}
            {/*        <ImageUpload id="file" onInput={inputHandler} multiple={true}/>*/}
            {/*    </div>*/}
            {/*</Modal>*/}
            {loadedDocuments.length ? (
                <div className="p-4">
                    {/*<DocumentHeader searchHandler={setSearch} btn_action={addDocumentButtonHandler}/>*/}
                    {/*<Pagination pageCount={pageCount} currentPage={currentPage} setCurrentPage={setCurrentPage}/>*/}
                    <DocumentWidget deleteButtonHandler={deleteButtonHandler} downloadHandler={downloadHandler} items={loadedDocuments}/>
                </div>
            ) : <div className="text-center text-gray-500 mt-5">{t('DocumentsNotFound')}</div>}

            {/*{!isLoading && !loadedDocuments && (*/}
            {/*    <ClientsNotFound/>*/}
            {/*)}*/}
        </Layout>);
};
export default Documents;
