import { useHttpClient } from "../../../hooks/http-hook";
import {useContext} from "react";
import {AuthContext} from "../../../context/auth-context";

export const authApi = () => {
    const { isLoading, sendRequest, error, clearError } = useHttpClient();
    const auth = useContext(AuthContext);
    const loginRequest = async (data) => {
        return await sendRequest(
            '/auth/admin/login',
            'POST',
            data,
            {
                'Content-Type': 'application/json'
            }
        )
    }
    const verificationRequest = async (data) => {
        return await sendRequest(
            '/auth/admin/verify',
            'POST',
            data,
            {
                'Content-Type': 'application/json'
            }
        )
    }

    const refreshTokenRequest = async (data) => {
        return await sendRequest(
            '/auth/admin/refresh',
            'POST',
            data,
            {
                'Content-Type': 'application/json'
            }
        )
    }

    const getPatientIdRequest = async (key) => {
        return await sendRequest(
            '/patient/key?secret_key='+key,
            'GET',
            undefined,
            {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token
            }
        )
    }
    return { loginRequest, verificationRequest, refreshTokenRequest, getPatientIdRequest, isLoading, error, clearError };
};
