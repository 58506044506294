import React from 'react';
import {useNavigate} from "react-router-dom";
import {t} from "i18next";
const Back = props =>{

    const navigate = useNavigate();
    return (
        <div onClick={ ()=>{navigate(-1)}} className="absolute flex-center cursor-pointer" style={{"top": "-30px"}}>
            <img src="/icons/arrow-back.svg" alt=""/>
            <div className="ml-2 text-sm text-gray-400">{t("GoBack")}</div>
        </div>
    );
}
export default Back;