import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {surveyApi} from "../api/api";
import Layout from "../../../layouts/Layout";
import LoadingPageSpinner from "../../../components/Loading/LoadingPageSpinner";
import Survey from "../../../components/Survey/Survey";
import Alert from "../../../components/Alert/Alert";
import Back from "../../../components/Button/Back";


const SurveyAnswer = () => {
    const { getSurveyRequest, submitSurveyRequest, isLoading, error, clearError } = surveyApi();
    const [loadedSurvey, setLoadedSurvey] = useState();

    const assign_id = useParams().assign_id;
    const [errorMessage, setErrorMessage] = useState();
    const [updated, setUpdated] = useState(true);
    if (!assign_id) {
        return 404;
    }
    useEffect(() => {
        const fetchSurvey = async () => {
            console.log(33445);
            try {
                const responseData = await getSurveyRequest(assign_id);
                // const responseData = await getSubClientSurvey(subclientId, surveyId);
                // const responseData = await getSurveyRequest(surveyId);
                const item = responseData.data;
                setLoadedSurvey(item);
            } catch (err) {
                console.log(err);
            }
        };
        fetchSurvey();

    }, [updated]);

    const submitSurvey = async (answers) => {
        try {

            let data = {"assign_id": assign_id, "answers": answers};
            const responseData = await submitSurveyRequest(JSON.stringify(data));
            setUpdated(prevMode => !prevMode);
        } catch (err) {
            console.log(err);
        }
    }
    return (
        <Layout>
            {(isLoading) && (<LoadingPageSpinner />)}
            <Back/>
            <Alert error={errorMessage ? errorMessage : error}/>
            {<Survey assign_id={assign_id} setErrorMessage={setErrorMessage}
                                    submitSurvey={submitSurvey} loadedSurvey={loadedSurvey}/>}
        </Layout>
    )
}

export default SurveyAnswer;
