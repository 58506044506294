import React from "react";
import {t} from "i18next";

const EmptySurveyWidget = () => {

    return (
        <>
            <div className={`my-4 bg-card_bg rounded-md p-3 flex items-center justify-between survey-item`}>
                <div className="w-9/10">
                    <div className="flex-center">
                        <div className={`hidden`}>
                            <div style={{"width": "17px", "height": "17px"}}
                                 className="item-select mr-4 rounded-full bg-gray-300 cursor-pointer">
                            </div>
                        </div>
                        <div>
                            <div className="text-sm text-red-400">{t("Language")}</div>
                            <div className="text-gray-600 font-bold lg:text-base text-sm mr-1">{t("FutureSurvey")}</div>
                        </div>
                    </div>
                </div>
                <div className="w-1/10 flex-center">

                    <div className="flex-center">
                        <div>
                            <div className="text-sm text-gray-500">{t("SurveyStatus")}</div>
                            <div className={`text-delete_bg font-bold lg:text-md text-sm`}>{t("NotCompleted")}</div>
                        </div>
                    </div>
                    {/*<img className="mx-4" src="/icons/arrow-right.svg" alt=""/>*/}
                </div>
            </div>

        </>
    )
}

export default EmptySurveyWidget;
