import React from "react";

const SubClientSelect = (props) => {
    return (<>

        {props.subClientId && <select value={props.subClientId} onChange={(e)=>{props.setSubClientId(e.target.value)}}
                                      className="p-3 cursor-pointer classic-input bg-transparent z-50" name="" id="">
            {props.subClients.map((q) =>
                <option key={q._id} value={q._id}>{q.relation.category_id}</option>
            )}
        </select>}
        </>
    )
    // return (<select  onChange={(e)=>{props.setSubClientId(e.target.value)}}
    //                  className="p-3 cursor-pointer classic-input bg-transparent z-50" name="" id="">
    //     {props.subClients.map((q) =>
    //         <option key={q._id} value={q._id}>{q.relation.category_id.label}-{q._id}</option>
    //     )}
    // </select>)
}
export default SubClientSelect;