import React from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import Auth from "./modules/auth/Auth";
import Verification from "./modules/auth/Verification";
import Dashboard from "./modules/dashboard/Dashboard";
import { AuthContext } from "./context/auth-context";
import { useAuth } from "./hooks/auth-hook";
import Surveys from "./modules/surveys/Surveys";
import Documents from "./modules/documents/Documents";
import { useTranslation } from "react-i18next";
import NotFound from "./components/NotFound/NotFound";
import SurveyAnswer from "./modules/surveys/subclient-survey/SurveyAnswer";
import DocumentsTab from "./modules/documents/DocumentsTab";
import DocumentsAdminTab from "./modules/documents/DocumentsAdminTab";
import AssignDocument from "./modules/documents/AssignDocument";
import QuestionDetails from "./components/Questions/QuestionDetails";
import Questions from "./modules/questions/Questions";
import DefinePatient from "./modules/auth/DefinePatient";
const App = () => {
  const { i18n } = useTranslation();
  window.changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };
  const {
    token,
    refresh_token,
    userId,
    userData,
    login,
    logout,
    verify,
    verifyData,
    updateToken,
    patient_id,
    savePatientID,
    secret_key,
    saveSecretKey,
  } = useAuth();

  let routes;

  if (token && patient_id) {
    console.log(patient_id);
    routes = (
      <Router>
        <Routes>
          <Route path="/" element={<Navigate replace to="/dashboard" />} />
          <Route path="/dashboard" element={<Dashboard />}></Route>
          <Route path="/surveys" element={<Surveys />}></Route>
          <Route
            path="/surveys/answer/:assign_id"
            element={<SurveyAnswer />}
          ></Route>
          <Route path="/questions" element={<Questions />}></Route>
          <Route
            path="/questions/answer/:assign_id"
            element={<QuestionDetails />}
          ></Route>
          <Route path="/documents" element={<Documents />}></Route>

          <Route
            path="/clients/documents-tab-assign/:patient_id/:subclient_id"
            element={<AssignDocument />}
          ></Route>
          <Route
            path="/clients/documents-tab/:patient_id/:subclient_id?"
            element={<DocumentsTab />}
          ></Route>
          <Route
            path="/clients/documents-tab/admin/:patient_id/:subclient_id?"
            element={<DocumentsAdminTab />}
          ></Route>

          <Route
            path="/login"
            element={<Navigate replace to="/dashboard" />}
          ></Route>
          <Route path="/patient/define" element={<DefinePatient />}></Route>
        </Routes>
      </Router>
    );
  } else {
    routes = (
      <Router>
        <Routes>
          <Route path="/verification/:item" element={<Verification />}></Route>
          <Route path="/patient/define" element={<DefinePatient />}></Route>
          <Route path="/" element={<Navigate replace to="/login" />} />
          <Route
            path="/dashboard"
            element={<Navigate replace to="/login" />}
          ></Route>
          <Route path="/login" element={<Auth />}></Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        refresh_token: refresh_token,
        userId: userId,
        userData: userData,
        verifyData: verifyData,
        login: login,
        logout: logout,
        verify: verify,
        updateToken: updateToken,
        patient_id: patient_id,
        savePatientID: savePatientID,
        secret_key: secret_key,
        saveSecretKey: saveSecretKey,
      }}
    >
      <main>{routes}</main>
    </AuthContext.Provider>
  );
};

export default App;
