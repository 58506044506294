import React, {useContext} from "react";
import {useLocation} from "react-router-dom";
import {AuthContext} from "../context/auth-context";
import {useTranslation} from "react-i18next";
import Header from "../components/Header/Header";
import Sidebar from "../components/Sidebar/Sidebar";

const Layout = ({ children })=>{
    const routeName = useLocation();
    const auth = useContext(AuthContext);
    let title = routeName.pathname.split("/");
    title = title.length ? title[1].charAt(0).toUpperCase() + title[1].slice(1) : "";

    let name = "Guest.";
    if(auth.userData && auth.userData.name){
        name = auth.userData.name.firstname + " " +auth.userData.name.lastname;
    }
    const { i18n } = useTranslation();

    return (
       <React.Fragment>
           <div className="bg-card_bg">
           <div className="flex min-h-screen max-w-width_extra mx-auto">
               <Sidebar/>
               <div className="w-full p-4">
                   <Header title={title} user_name={name}/>
                   {/*<div id="tabs-hook"></div>*/}
                   <div className="w-full bg-white rounded-md border relative" style={{minHeight: "calc(100vh - 150px)"}}>
                       <main>{children}</main>
                   </div>
               </div>
           </div>
           </div>
       </React.Fragment>
    )
}
export default Layout;
