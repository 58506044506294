import React, { useContext, useState } from "react";
import { VALIDATOR_REQUIRE } from "../../utils/validation/validators";
import { AuthContext } from "../../context/auth-context";
import { useNavigate } from "react-router-dom";
import { useForm } from "../../hooks/form-hook";
import { useParams } from "react-router-dom";
import { authApi } from "./api/api";
import Input from "../../components/Input/Input";
import Alert from "../../components/Alert/Alert";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import variables from "../../constants/variables";
import { t } from "i18next";
import PublicLayout from "../../layouts/PublicLayout";
import OtpInput from "react18-input-otp";

const Verification = () => {
  const initialInputs = [];
  const inputs = [];
  const [message, setMessage] = useState(null);
  const item = useParams().item;
  const title = item === "phone" ? t("VerifyPhone") : t("VerifyEmail");

  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const { loginRequest, verificationRequest, isLoading, error, clearError } =
    authApi();

  if (item !== "email" && item !== "phone") {
    return 404;
  }

  if (!auth.verifyData.userId) {
    navigate("/login");
  }
  const onKeyupTab = (e) => {
    let next = e.target.parentElement.nextSibling;
    if (next) {
      const input = next.querySelector("input");
      if (input) {
        input.focus();
      }
    }
  };

  for (let i = 1; i <= variables.verificationCodeLength; i++) {
    let id = "code" + i;
    initialInputs[id] = {
      value: "",
      isValid: false,
    };
  }
  const [formState, inputHandler] = useForm(initialInputs, false);

  for (let i = 1; i <= variables.verificationCodeLength; i++) {
    let id = "code" + i;
    initialInputs[id] = {
      value: "",
      isValid: false,
    };
    inputs.push(
      <Input
        key={id}
        styles="classic-input text-center"
        marginStyles="my-2 mx-1"
        placeholder=""
        element="input"
        id={id}
        type="text"
        validators={[VALIDATOR_REQUIRE()]}
        onInput={inputHandler}
        onKeyup={onKeyupTab}
      />
    );
  }

  const sendAgainHandler = async () => {
    try {
      await loginRequest(auth.verifyData.sendData);
      setMessage("Success!");
      setTimeout(() => {
        setMessage(null);
      }, 3000);
    } catch (err) {
      console.log(err);
    }
  };

  const [otp, setOtp] = useState("");
  const handleChange = (enteredOtp) => {
    setOtp(enteredOtp);
  };

  const verificationSubmitHandler = async (event) => {
    event.preventDefault();
    try {
      clearError();
      // let otp = "";
      //
      // for (const [key, value] of Object.entries(formState.inputs)) {
      //     otp += value.value;
      // }
      const data = {
        otp: otp,
        user_id: auth.verifyData.userId,
      };

      await verificationRequest(JSON.stringify(data)).then((res) => {
        auth.login(
          res.data.user._id,
          res.data.user,
          res.data.token,
          res.data.refresh_token
        );
        navigate("/patient/define");
      });
    } catch (err) {}
  };

  return (
    <PublicLayout>
      <Alert style={"h-24"} error={error} message={message} />
      <div className="flex font-classic">
        <div className="classic-card py-6">
          <p className="text-center text-sm font-bold mb-3">{title}</p>
          <div className="flex items-center justify-center my-4">
            <OtpInput
              value={otp}
              onChange={handleChange}
              numInputs={variables.verificationCodeLength}
              inputStyle={
                "classic-input text-center mx-1 w-8 px-2 py-1 text-gray-500"
              }
            />
          </div>
          <form onSubmit={verificationSubmitHandler}>
            {/*<div className="flex mx-8">{inputs}</div>*/}
            <div className="my-3 flex items-center justify-center text-gray-400">
              {t("ReceiveCode")}
              <div
                onClick={sendAgainHandler}
                className="cursor-pointer ml-2 font-bold text-gray-600"
              >
                {t("SendAgain")}
              </div>
            </div>
            <button
              className="w-full classic-btn"
              type="submit"
              disabled={otp.length < 6}
            >
              <div className="relative">
                <LoadingSpinner isLoading={isLoading} />
                <span>{t("VerifySignIn")}</span>
              </div>
            </button>
          </form>
        </div>
      </div>
    </PublicLayout>
  );
};

export default Verification;
