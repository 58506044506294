import React, {useEffect, useState} from "react";
import Tabs from "../../components/Tabs/Tabs";
import Layout from "../../layouts/Layout";
import {Link, useParams} from "react-router-dom";
import {documentsApi} from "./api/api";
import DocumentWidget from "../../components/Documents/DocumentWidget";
import Alert from "../../components/Alert/Alert";
import SubClientSelect from "../../components/SubClients/SubclientSelect";
import {t} from "i18next";
// import {useGetSubClients} from "../../hooks/subclients-hook";
import Modal from "../../components/Dialog/Modal";
import LoadingPageSpinner from "../../components/Loading/LoadingPageSpinner";

const DocumentsAdminTab = () => {
    const userId = useParams().patient_id;
    const [updated, setUpdated] = useState(false);
    if(!userId){
        return 404;
    }
    const [subClientId, setSubClientId] = useState(useParams().subclient_id);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const { getSubClientAssignedDocumentsRequest, downloadDocumentRequest, deleteSubClientDocumentRequest, updateDocumentDescription, isLoading,  error, clearError } = documentsApi();
    const [loadedDocuments, setLoadedDocuments] = useState([]);
    const [deleteId, setDeleteId] = useState(false);
    const [editId, setEditId] = useState(false);
    const [description, setDescription] = useState("");
    useEffect(() => {
        const fetchDocuments = async () => {
            try {
                const responseData = await getSubClientAssignedDocumentsRequest(subClientId);
                const items = responseData.data ? responseData.data.result : [];
                setLoadedDocuments(items);
            } catch (err) {
                console.log(err);
            }
        };

        if(subClientId){
            fetchDocuments();
        }

    }, [subClientId, updated]);
    
    const editDocumentHandler = (_id, desc) => {
        setEditId(_id);
        setDescription(desc);
        setShowEditDialog(true);
    }
    const editDocumentDescriptionHandler = async () => {
        setShowEditDialog(false);
        const data = {"_id": editId, description: description};
        const responseData = await updateDocumentDescription(JSON.stringify(data));
        setUpdated(prevMode => !prevMode);
    }
    const downloadHandler = async (_id) => {

        try{
            const response = await downloadDocumentRequest(_id);
            const url = response.data;
            window.open(
                url,
                '_blank' // <- This is what makes it open in a new window.
            );
        }catch (e){}

    }
    const deleteButtonHandler = (id)=>{
        setDeleteId(id);
        setShowDeleteDialog(true);
    }
    const deleteDocumentsHandler = async event => {

        event.preventDefault();

        try {
            setShowDeleteDialog(false);
            const responseData = await deleteSubClientDocumentRequest(deleteId);
            setUpdated(prevMode => !prevMode);

        } catch (err) {
        }
    }

    const {subClients} = useGetSubClients();
    return (
       <Layout>
           <Tabs patient_id={userId} subclient_id={subClientId} active_tab={"documents-admin"}/>
           <Alert error={error}/>
           {isLoading && (<LoadingPageSpinner/>)}
           <Modal
               show={showDeleteDialog}
               className={"dialog bg-dialog_bg w-width_smx"}
               onCancel={()=>{setShowDeleteDialog(false)}}
               headerClass={"dialog-header text-lg text-red-500"}
               header={t('RemovingDocument')}
               contentClass="p-4"
               footer={
                   <div className="p-4">
                       <div className="w-full cancel-btn mb-2" onClick={()=>{setShowDeleteDialog(false)}}>{t("Cancel")}</div>
                       <div className="w-full delete-btn" onClick={deleteDocumentsHandler}>{t("Delete")}</div>
                   </div>
               }
           >
               <div className="w-full text-gray-600 text-lg my-6">
                   {t("deleteAssignedDocument")}
               </div>
           </Modal>

           <Modal
               show={showEditDialog}
               className={"dialog bg-dialog_bg w-width_smx"}
               onCancel={()=>{setShowEditDialog(false)}}
               headerClass={"dialog-header text-lg text-gray-600"}
               header={t("Edit Description")}
               contentClass="p-4"
               footer={
                   <div className="p-4">
                       <div className="w-full cancel-btn mb-2" onClick={()=>{setShowEditDialog(false)}}>{t("Cancel")}</div>
                       <div className="w-full submit-btn" onClick={editDocumentDescriptionHandler}>{t("Submit")}</div>
                   </div>
               }
           >
               <div className="w-full text-gray-600 text-lg my-6">
                   <textarea onChange={(e)=>{setDescription(e.target.value)}} className="w-full border border-gray-300 p-2 rounded-md" rows="3" defaultValue={description}></textarea>
               </div>
           </Modal>
           {subClients && subClientId &&

               <div className="flex items-center justify-between m-4 ">
                   <div className=" md:w-1/5 relative">
                       <img className="absolute right-2 top-12 z-10" src="/icons/arrow-t-down.svg" alt=""/>
                       <div className="text-sm text-gray-500 mb-1">{t("ChooseSubClient")}</div>
                       <SubClientSelect subClientId={subClientId} subClients={subClients} setSubClientId={setSubClientId}/>
                   </div>
                   <Link to={`/clients/documents-tab-assign/${userId}/${subClientId}`}>
                       <div className="orange-btn">
                           <img className="mr-2" src="/icons/survey.svg" alt=""/>
                           <div>Assign Documents</div>
                       </div>
                   </Link>
               </div>

           }
           {loadedDocuments && (
               <div className="p-4">
                   <DocumentWidget editDocumentHandler={editDocumentHandler}  deleteButtonHandler={deleteButtonHandler} downloadHandler={downloadHandler} items={loadedDocuments}/>
               </div>
           )}
           {!subClients.length && !isLoading ?
               <div className="p-4">
                   <div className="flex-center text-gray-400 text-lg">
                       <div>
                           {t("HasNoSunClients")}
                           <Link to={`/clients/edit/${userId}`}>
                               <div className="mt-4 orange-btn">{t("AddSubClient")}</div>
                           </Link>
                       </div>
                   </div>
               </div> : !loadedDocuments.length ?
                   <div className="p-4">
                       <div className="flex-center text-gray-400 text-lg">{t("SubClientHasNoAssignedDocuments")}</div>
                   </div> : <div></div>
           }
       </Layout>
    )
}

export default DocumentsAdminTab;
