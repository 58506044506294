import React, {useContext, useEffect, useState} from "react";
import {t, use} from "i18next";
import EditButton from "../Button/EditButton";
import { v4 as uuid } from 'uuid';
import AddButton from "../Button/AddButton";
import {Link, useParams} from "react-router-dom";
import {questionsApi} from "../../modules/questions/api/api";
import Alert from "../Alert/Alert";
import LoadingPageSpinner from "../Loading/LoadingPageSpinner";
import {documentsApi} from "../../modules/documents/api/api";
import {AuthContext} from "../../context/auth-context";
const Question = (props) => {

    const loadedQuestion = props.loadedQuestion;
    const [editMode, setEditMode] = useState(false);
    const [autoMode, setAutoMode] = useState(true);
    const [generatedQuestions, setGeneratedQuestions] = useState();
    const [generatedTitles, setGeneratedTitles] = useState({});
    const [updated, setUpdated] = useState(false);
    const { submitQuestion, getSubClientAnswers, getQuestion, isLoading, error, clearError } = questionsApi();
    const { uploadDocumentsRequest, deleteProbabilityDocument } = documentsApi();
    const [message, setMessage] = useState("");
    const probabilityDocuments = React.useState({});
    const probabilityDeleteDocuments = React.useState({});
    const [errorMessage, setErrorMessage] = useState("");
    const [isEdited, setIsEdited] = useState(false);


    const editButtonHandler = () =>{
        setEditMode(true);
        setIsEdited(true);
    }

    const formatArray = (arrayOfProbabilities) => {

        let arrayOfMulti = [];

        for (let i = 0; i < arrayOfProbabilities.length; i++) {
            let item = arrayOfProbabilities[i];
            if(item){
                for(let k= 0; k < item.length; k++){
                    let subItem = item[k];
                    if(subItem.type === "multi-choice"){
                        arrayOfProbabilities[i].splice(k, 1);
                        arrayOfMulti.push(subItem);
                        k--;
                    }
                }
            }
        }
        // removing duplicates
        for (let ii = 0; ii < arrayOfProbabilities.length; ii++) {
            let item = arrayOfProbabilities[ii];
            for(let kk= 0; kk < item.length; kk++){
                if(item[kk]._id === "60c791d2f5a8f58a5ad48e37"){
                    arrayOfProbabilities[ii] = [...new Map(arrayOfProbabilities[ii].map((item_) => [item_._id, item_])).values()];
                }
            }
        }
        //end
        for(let n= 0; n < arrayOfMulti.length; n++){
            let multi = arrayOfMulti[n];

            for (let i = 0; i < arrayOfProbabilities.length; i++) {
                let item = arrayOfProbabilities[i];
                if(item){
                    for(let k= 0; k < item.length; k++){
                        let subItem = item[k];
                        if(subItem.parent_id === multi._id) {
                            arrayOfProbabilities[i].splice(k, 0, multi);
                            break;
                        }
                    }
                }
            }
        }

        return arrayOfProbabilities;
    }

    const resetSiblings = (probability, e) => {
        const probabilitiesContainer = e.target.closest('.probabilities');
        let siblings = probabilitiesContainer.querySelectorAll(`.p_item[data-parent_id="${probability.parent_id}"]`);

        siblings.forEach(sibling => {

            if(sibling.dataset.id != probability._id){
                if(sibling.dataset.ptype === "choice" || !sibling.dataset.ptype){

                    let point = sibling.querySelector('.answer-point');
                    if(point){
                        point.classList.remove('bg-blue-400')
                        point.classList.add("bg-gray-200");
                    }
                }

                if(sibling.dataset.ptype === "free"){

                    let input = sibling.querySelector('input.classic-input');
                    if(input){
                        input.value = ""
                    }
                }
            }

        })
    }
    const auth = useContext(AuthContext);
    useEffect(() => {
        const updateLoadedQuestions = async () => {
            try {
                let loadedQuestion;
                const fetchQuestion = async () => {
                    try {
                        // let responseData = await getSubClientAnswers(props.assign_id);
                        const res = await fetch(process.env.REACT_APP_API_URL +  `/questions/answers?assign_id=${props.assign_id}`, {
                            method: 'get',
                            body: undefined,
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: 'Bearer ' + auth.token
                            }
                        });
                        let responseData = await res.json();
                        loadedQuestion = responseData.data;

                    } catch (err) {
                        console.log(err);
                    }
                };
                fetchQuestion().then(()=>{
                    let q = loadedQuestion ? Array.from(loadedQuestion.sections) : []
                    q.forEach(section => {
                        section.questions.forEach(question => {

                            const newProbabilities = Object.values(question.probabilities.reduce((acc, obj) => {
                                const { parent_id } = obj;
                                acc[parent_id] = acc[parent_id] || [];
                                acc[parent_id].push(obj);
                                return acc;
                            }, {}));

                            question.probabilities = formatArray(newProbabilities);

                        })
                    });

                    if (props.subclientId) {

                        let showAnswersResult = showAnswers(q);
                        setGeneratedQuestions(showAnswersResult[0]);
                        setGeneratedTitles(showAnswersResult[1]);

                        console.log(showAnswersResult[0]);
                        console.log(showAnswersResult[1]);

                    } else {
                        setGeneratedQuestions(q);
                    }

                })

            } catch (err) {
                console.log(err);
            }
        };
        updateLoadedQuestions();

    }, [updated]);

    const itemFull = (probability) => {
        let check = false;
        if(probability.type === "upload-request" || probability.type === "multi-choice" || probability.type === "free"){
            check = true
        }
        return check;
    }
    const setAnswer = (e, answerID, scoreValue) => {
        if (editMode) {
            const probabilitiesContainer = e.target.closest('.probabilities');
            const probabilityItem = e.target.parentElement;
            const points= probabilitiesContainer.querySelectorAll('.answer-point');
            let allItems = probabilitiesContainer.querySelectorAll(`.p_item`);
            if (probabilitiesContainer) {
                //if its main probability
                if(!probabilityItem.dataset.parent_id){
                    let id = probabilityItem.dataset.id;
                    // we need to remove prev main probability and its children

                    //make all points gray
                    if (points) {
                        points.forEach(item => {
                            item.classList.remove("bg-blue-400");
                            item.classList.add("bg-gray-200");
                        });
                        //make main point blue
                        e.target.classList.add('bg-blue-400');
                        e.target.classList.remove('bg-gray-200');

                        // hide all children
                        allItems.forEach(item => {
                            if(item.dataset.parent_id && item.dataset.parent_id !== id){
                                item.classList.add('hidden');
                            }
                        })
                    }
                    //  show needed children
                    let hidden = probabilitiesContainer.querySelectorAll(`.p_item[data-parent_id="${id}"]`);
                    // console.log(hidden);
                    hidden.forEach(item => {
                        item.classList.remove('hidden');
                    })
                }


                // if its nested probability
                if(probabilityItem.dataset.parent_id){

                    if (probabilityItem.dataset.ptype === "multi-select") {

                        //if it's checked:
                        let anw = probabilityItem.querySelector('.answer-point');
                        let title = document.querySelector(`.sibling-probabilities[data-parent="${probabilityItem.dataset.id}"]`);

                        if(anw && anw.classList.contains('bg-blue-400')){

                            if (title) {
                                title.classList.add('hidden');
                                title.innerText = "";
                            }
                            anw.classList.remove('bg-blue-400');
                            anw.classList.add('bg-gray-200');

                            let children = probabilitiesContainer.querySelectorAll(`.p_item[data-parent_id="${probabilityItem.dataset.id}"]`);
                            children.forEach(child => {
                                child.classList.add('hidden');
                            })
                        }else{
                            //if it's not checked:
                            let p_desc = probabilityItem.querySelector(".p_desc");
                            if (title && p_desc) {
                                title.classList.remove('hidden');
                                title.innerText = p_desc.innerText.toUpperCase() + ":";
                            }
                            // make current probability blue
                            e.target.classList.add('bg-blue-400');
                            e.target.classList.remove('bg-gray-200');

                            // show current probability children
                            let children = probabilitiesContainer.querySelectorAll(`.p_item[data-parent_id="${probabilityItem.dataset.id}"]`);
                            children.forEach(child => {
                                child.classList.remove('hidden');
                            })
                        }
                    }

                    if(probabilityItem.dataset.ptype !== "multi-select") {
                        // make all siblings gray
                        allItems.forEach(item => {
                            if (item.dataset.parent_id && item.dataset.parent_id === probabilityItem.dataset.parent_id) {
                                let an = item.querySelector('.answer-point');
                                if (an) {
                                    an.classList.remove("bg-blue-400");
                                    an.classList.add("bg-gray-200");
                                }


                                //make their children hidden
                                let children = probabilitiesContainer.querySelectorAll(`.p_item[data-parent_id="${item.dataset.id}"]`);
                                children.forEach(child => {
                                    child.classList.add('hidden');
                                    // if type multi-choice
                                    if (child.dataset.ptype === "multi-choice") {
                                        //show children
                                        let childrenOfChildren = probabilitiesContainer.querySelectorAll(`.p_item[data-parent_id="${child.dataset.id}"]`);
                                        childrenOfChildren.forEach(child => {
                                            child.classList.add('hidden');
                                        });
                                    }
                                })
                            }
                        })
                        // make current probability blue
                        e.target.classList.add('bg-blue-400');
                        e.target.classList.remove('bg-gray-200');

                        // show current probability children
                        let children = probabilitiesContainer.querySelectorAll(`.p_item[data-parent_id="${probabilityItem.dataset.id}"]`);
                        children.forEach(child => {
                            child.classList.remove('hidden');
                        })
                    }
                }

                let children = probabilitiesContainer.querySelectorAll(`.p_item[data-parent_id="${probabilityItem.dataset.id}"]`);
                children.forEach(child => {
                   // if type multi-choice
                    if(child.dataset.ptype === "multi-choice"){
                        //show children
                        let childrenOfChildren = probabilitiesContainer.querySelectorAll(`.p_item[data-parent_id="${child.dataset.id}"]`);
                        childrenOfChildren.forEach(child => {
                            child.classList.remove('hidden');
                        });
                    }
                })

            }
        }
    }

    const submitForm = async (e) => {
        if (editMode) {
            let data = [];

            let allSections = document.querySelectorAll('.section_block');
            allSections.forEach(section => {
                let sectionObj = {};
                sectionObj.section_id = section.dataset.section_id;
                sectionObj.questions = [];

                let questions = section.querySelectorAll('.question_block');

                questions.forEach(question => {

                    let questionObj = {};
                    questionObj.question_id = question.dataset.question_id;
                    questionObj.probabilities = [];

                    let probabilities = question.querySelectorAll('.p_item');
                    probabilities.forEach(probability => {
                        if (!probability.classList.contains('hidden')) {
                            //dots
                            let selected = probability.querySelector('.answer-point.bg-blue-400');
                            if (selected) {
                                if (!probability.dataset.parent_id) {
                                    questionObj.probability_id = probability.dataset.id;
                                } else if (probability.dataset.parent_id) {
                                    questionObj.probabilities.push({
                                        question_id: probability.dataset.parent_id,
                                        probability_id: probability.dataset.id
                                    });
                                }
                            }
                            //free
                            let input = probability.querySelector('.free-input');
                            if (input && input.value !== "") {
                                if (probability.dataset.parent_id) {
                                    questionObj.probabilities.push({
                                        question_id: probability.dataset.id,
                                        other_text: input.value
                                    });

                                    //if user creates new inputs
                                    let newInputs = probability.querySelectorAll('.new-free');
                                    newInputs.forEach(newInput => {
                                        if (newInput.value) {
                                            questionObj.probabilities.push({
                                                question_id: probability.dataset.id,
                                                other_text: newInput.value,
                                                add_probability: true
                                            });
                                        }
                                    })

                                } else {
                                    // questionObj.probability_id = probability.dataset.parent_id
                                    questionObj.probability_id = probability.dataset.id
                                    questionObj.other_text = input.value
                                }
                            }
                        }
                    });

                    let freeQuestions = question.querySelectorAll('.question-free');
                    freeQuestions.forEach(free => {
                        if (free.value !== "") {
                            questionObj.other_text = free.value
                        }
                    })

                    sectionObj.questions.push(questionObj);
                });
                data.push(sectionObj);
            });

            try {
                const sendData = {"assign_id": props.assign_id, "answers": data};
                // console.log(JSON.stringify(sendData));


                let all = [];

                const submitPromise = (() => {
                    if (autoMode && !e) {
                        return fetch(process.env.REACT_APP_API_URL + '/questions/answers', {
                            method: 'post',
                            body: JSON.stringify(sendData),
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: 'Bearer ' + auth.token
                            }
                        });
                    }else{
                        return submitQuestion(JSON.stringify(sendData));
                    }

                });

                all.push(submitPromise);

                // for (const property in probabilityDocuments[0]) {
                //     const uploadPromise = (() => {
                //         return uploadDocumentsRequest(probabilityDocuments[0][property].data);
                //     });
                //     all.push(uploadPromise);
                // }

                if(e) {
                    for (const property in probabilityDeleteDocuments[0]) {
                        const deletePromise = (() => {
                            return deleteProbabilityDocument(probabilityDeleteDocuments[0][property]);
                        });
                        all.push(deletePromise);
                    }
                }
                const callRequests = async (arr) => {
                    let i = arr.length - 1;
                    while (i >= 0) {
                        console.log(i);
                        await arr[i]();
                        if (i === 0) {
                            return Promise.resolve();
                        }
                        i--;
                    }
                }

                await callRequests(all).then(() => {
                    if (e) {
                        setIsEdited(false);
                        setMessage(t("Success!"));
                        setEditMode(false);
                        probabilityDocuments[1]({})
                        probabilityDeleteDocuments[1]({})
                        setUpdated(prevMode => !prevMode)

                        setTimeout(() => {
                            setMessage("");
                        }, 2000);
                    }
                }).catch(e => {
                    setErrorMessage(e.message);
                    probabilityDocuments[1]({})
                    probabilityDeleteDocuments[1]({})
                    setUpdated(prevMode => !prevMode)

                    setTimeout(() => {
                        setErrorMessage("");
                    }, 2000);
                });


            } catch (err) {
                console.log(err);
            }
        }
    }

    const showAnswers = (generatedQuestions) => {

        let showTiles = {};
        generatedQuestions.forEach(section => {
            section.questions.forEach(question => {

                question.probabilities.forEach(probArr => {
                    probArr.forEach(probItem => {
                        if (probItem.type === "multi-choice" && !probItem.parent_id) {
                            question.probabilities.forEach(prA => {
                                prA.forEach(prI => {
                                    if (prI.parent_id === probItem._id) {
                                        prI.show = true;
                                    }
                                })
                            })
                        }
                    })
                });
                if(question.probability_id){
                    question.probabilities.forEach(probArr => {
                        probArr.forEach(probItem => {

                            if(!probItem.parent_id){
                                if(probItem._id === question.probability_id){
                                    // probItem.show = true;
                                    probItem.blue = true;

                                    //show children
                                    question.probabilities.forEach(prA=>{
                                        prA.forEach(prI=>{
                                            if(prI.parent_id === probItem._id){
                                                prI.show = true;
                                            }
                                        })
                                    })
                                }

                            }
                            if(probItem.probability_id){
                                probItem.show = true;

                                //show all sibling and children
                                probArr.forEach(probItemS => {
                                    probItemS.show = true;
                                    if(probItemS._id === probItem.probability_id){
                                        probItemS.blue = true;
                                    }
                                })

                                question.probabilities.forEach(prA=>{
                                    prA.forEach(prI=>{
                                        if(prI._id === probItem.probability_id){
                                            prI.blue = true;
                                            prI.show = true;
                                        }
                                        if(prI.parent_id === probItem.probability_id){
                                            prI.show = true;
                                        }
                                    })
                                })
                            }

                            if(probItem.other_text){
                                probItem.show = true;
                                //show all sibling
                                probArr.forEach(probItemS => {
                                    probItemS.show = true;
                                })

                                question.probabilities.forEach(prA=>{
                                    prA.forEach(prI=>{
                                        if(prI.parent_id === probItem._id){
                                            prI.show = true;
                                        }
                                        //if other text -> make parent blue
                                        if(prI._id === probItem.parent_id){
                                            if(prI.type === "multi-select"){
                                                showTiles[prI._id] = {};
                                                showTiles[prI._id].description = prI.description;
                                                showTiles[prI._id].id = prI._id;
                                                prI.show = true;
                                                prI.blue = true;
                                            }
                                            if(prI.add_probability){
                                                probItem.add_probability = true;
                                            }
                                        }
                                    })
                                })
                            }
                        })
                    })
                }else if(question.other_text){

                }
            })
        })

        return [generatedQuestions, showTiles];
    }
    const addDocumentButtonHandler = async (e, id) => {
        let el = e.target.closest('.upl');
        let files = e.target.files;
        if(files){
            let div = el.querySelector('.file-name');
            if(files[0]){

                const formData = new FormData();
                formData.append('probability_id', id);
                formData.append('patient_id', auth.patient_id);
                formData.append('DOCUMENT', files[0]);

                let docs = probabilityDocuments[0];
                docs[id] = {};
                docs[id]['data'] = formData;
                docs[id]['name'] = files[0].name;
                probabilityDocuments[0] = docs;

                const response = await fetch(process.env.REACT_APP_API_URL + '/documents', {
                    method: 'post',
                    body: formData,
                    headers: {
                        Authorization: 'Bearer ' + auth.token
                    }
                })

                const responseData = await response.json();
                console.log(responseData);
                if(responseData.success){
                    div.style.color = 'black';
                    div.innerText = files[0].name;
                }else{
                    div.innerText = t('InvalidFormat');
                    div.style.color = 'red';
                }

            }
        }
    }

    const handleDeleting = (e, id)=>{
        let parent = e.target.closest('.doc-block');
        if (e.target.innerText !== 'Deleted') {
            e.target.innerText = 'Deleted';
            // if(parent){
            //     parent.querySelector('.restore-doc').classList.remove('hidden');
            // }
            let obj = probabilityDeleteDocuments[0];
            obj[id] = id;
            probabilityDeleteDocuments[0] = obj;
            let box = e.target.closest('.upload-box');
            if(box){
                let inner = box.querySelector('.upload-inner');
                if(inner){
                    inner.classList.remove('hidden');
                }
            }
            // console.log("delete: ")
            // console.log(probabilityDeleteDocuments[0]);
        }
    }

    const handleRestoring = (e, id)=>{
        let parent = e.target.closest('.doc-block');
        e.target.classList.add('hidden');
        if(parent){
            parent.querySelector('.delete-doc').classList.remove('hidden');
        }
        let obj = probabilityDeleteDocuments[0];
        delete obj[id];
        probabilityDeleteDocuments[0] = obj;
        console.log("delete: ")
        console.log(probabilityDeleteDocuments[0]);
    }

    const addMore = (e) => {
        let example = e.target.parentElement.querySelector(".free-example");
        let container =  e.target.closest(".free-container");
        if(example && container){

            const clonedElement = example.cloneNode(true);
            clonedElement.value = ""
            clonedElement.classList.add('new-free');
            container.appendChild(clonedElement);
        }
    }

    const addMoreChild = (e) => {
        console.log(e.target.dataset.id);
        const divs = document.querySelectorAll(`div[data-parent_id="${e.target.dataset.id}"]`);
        divs.forEach(div => {
            const clonedElement = div.cloneNode(true);
            div.parentElement.appendChild(clonedElement);
        })

    }

    // useEffect(() => {
    //     const handler = (event) => {
    //         event.preventDefault();
    //         event.returnValue = "";
    //     };
    //
    //     const ajaxHandler = (event) => {
    //         event.preventDefault()
    //         if(event.target.parentElement.parentElement.href) {
    //             window.location.href = event.target.parentElement.parentElement.href;
    //         }else {
    //             const userConfirmed = window.confirm("Changes you made may not be saved. Do you want to leave the page?");
    //
    //             if (userConfirmed) {
    //                 window.history.go(-1);
    //             }
    //         }
    //     }
    //
    //     if (isEdited) {
    //         document.querySelectorAll('a').forEach(item => {
    //             item.addEventListener('click', ajaxHandler)
    //         })
    //         window.addEventListener("beforeunload", handler);
    //         return () => {
    //             window.removeEventListener("beforeunload", handler);
    //         };
    //     }
    //     return () => {};
    // }, [isEdited]);

    useEffect(() => {
        const interval = setInterval(async () => {
            if (autoMode) {
                console.log('intrval');
                    await submitForm()
            }
        }, 10000);
        return () => clearInterval(interval);
    }, [editMode, autoMode]);

    return (
        <div>
            <Alert error={errorMessage || error} message={message}/>
            {(isLoading) && (<LoadingPageSpinner />)}
            {loadedQuestion && generatedQuestions && (<div className="p-5">
                <div className="w-full xl:mt-0 mt-2">
                    {props.subclientId && (<div className="flex-end">
                        {!editMode && <EditButton onClick={editButtonHandler} className="justify-center mr-4"
                                                  text={`${loadedQuestion.status_complete ? t("EditAnswers") : t("CompleteTheForm")}`}/>}
                        {editMode &&  <AddButton onclick={submitForm} withoutIcon={true} btn_text={t("SubmitForm")}/>}
                        {editMode && <div onClick={()=>{setEditMode(false)}} className="ml-4 cursor-pointer text-gray-500 text-sm">{t("Cancel")}</div>}
                    </div>)}
                </div>
                <div className="mt-2 mb-4">
                    <div className="flex-center w-full font-bold text-xl">{loadedQuestion.survey_info.name}</div>
                </div>
                    {generatedQuestions.map(section =>
                        <div key={section._id} className="section_block mb-4" data-section_id={section._id}>
                            <div className="text-center text-xl my-2">{section.label}</div>
                            {section.questions.map(question =>
                                <div className="mb-5 question_block" data-question_id={question._id} data-question_type={question.type} key={question._id}>
                                    <div className="mb-1 font-bold">{question.label}</div>
                                    <div className="break-words">{question.description}</div>
                                    {/*{question.type === "multi-choice" && (*/}
                                    {question.probabilities.length ? (

                                        <div className="probabilities">
                                            <div className="">
                                                {question.probabilities.map(siblingProbabilities =>
                                                <div key={uuid()}>

                                                    <div className="flex-start flex-wrap" key={uuid()}>
                                                        {siblingProbabilities.map((probability, index)=>
                                                            <div key={uuid()}
                                                                 data-parent_id={probability.parent_id}
                                                                 data-id={probability._id}
                                                                 data-question_id={question._id}
                                                                 data-section_id={section._id}
                                                                 data-ptype={probability.type}
                                                                 data-qtype={question.type}
                                                                 className={`p_item mr-4 flex-start flex-wrap  ${itemFull(probability) ? "item-full" : ""} ${(probability.parent_id && !probability.show) ? "hidden" : ""}`}
                                                            >

                                                            {index === 0 ?
                                                                <div className={`sibling-probabilities w-full ${!generatedTitles[siblingProbabilities[0].parent_id] ? "hidden" : "" } mt-3 text-blue-500 font-bold text-md`}
                                                                     data-parent={siblingProbabilities[0].parent_id}>
                                                                    {generatedTitles[siblingProbabilities[0].parent_id] ? generatedTitles[siblingProbabilities[0].parent_id].description.toUpperCase() : ""}
                                                                </div> : ""}

                                                            {(probability.type === "questions" || probability.type === "link") && (
                                                                <Link to={probability.hyperlink}><span className="text-blue-400 line">{probability.description}</span></Link>
                                                            )}

                                                            {(!probability.type || probability.type === "choice" || probability.type === "multi-select") && (
                                                                <>
                                                                    <div style={{"minWidth": "12px", "height": "12px"}}
                                                                         onClick={(e) => {
                                                                             setAnswer(e);
                                                                             resetSiblings(probability, e);
                                                                         }}
                                                                         className={`${(probability.blue) ? "bg-blue-400" : "bg-gray-200"}  mr-2 answer-point rounded-full ${editMode ? "cursor-pointer border border-gray-500" : ""}`}>
                                                                    </div>
                                                                    <div className='p_desc'>
                                                                        {probability.description}
                                                                    </div>
                                                                </>
                                                            )}
                                                            {(probability.type === "multi-choice") && (
                                                                <div>{probability.description}</div>
                                                            )}
                                                            {probability.type === 'upload-request' && <div className="w-full basis-full my-2 upload-box">

                                                                {probability.photo && <img className="w-full lg:w-1/2" src={probability.photo} alt=""/>}

                                                                {probability.hyperlink && <div className="flex-start doc-block">
                                                                    <Link to={probability.hyperlink}><div className="text-blue-400 font-bold">Open document</div></Link>
                                                                    {editMode && <div onClick={(e)=>handleDeleting(e, probability._id)} className="bg-red-400 hover:bg-red-500 px-3 py-1 rounded-md text-white cursor-pointer ml-2 text-sm delete-doc">{t("Delete")}</div>}
                                                                    {editMode && <div onClick={(e)=>handleRestoring(e, probability._id)} className="bg-green-400 hover:bg-green-500 px-3 py-1 rounded-md text-white cursor-pointer ml-2 text-sm restore-doc hidden">{t("Restore")}</div>}
                                                                </div>}
                                                                {probability &&
                                                                    <div className={`${probability.hyperlink ? "hidden upload-inner" : "block"}`}>
                                                                        <div className="w-full my-1 font-bold">{probability.description}</div>
                                                                        <div className="border bg-gray-100 w-full p-8 border-red-400 rounded-md">
                                                                            <div className="flex-center upl relative">
                                                                                <input id={uuid()}
                                                                                    className="opacity-0 absolute w-full p-10"
                                                                                    type="file"
                                                                                       accept="image/jpeg,image/jpg,image/png,.pdf,.docx,.doc" onChange={(e) => addDocumentButtonHandler(e, probability._id)}
                                                                                />
                                                                                <AddButton disabled={!editMode} className={"text-sm"} btn_text={t("UploadDocument")}/>
                                                                                <div className="file-name ml-2"></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>}
                                                            </div>}
                                                            {probability.type === 'free' &&
                                                                <div className="mb-4 w-full">
                                                                    <div className="my-1 font-bold">{probability.description}</div>
                                                                    <div className="free-container">
                                                                        {!probability.add_probability ? <input readOnly={!editMode} defaultValue={probability.other_text} type="text" className="free-input classic-input w-full"/> : ""}
                                                                        {probability.add_probability ? <div className="flex-between w-full">
                                                                            <input defaultValue={probability.other_text} type="text" className="free-input classic-input my-2 free-example"/>
                                                                            {index === 0 && editMode ? <div onClick={(e)=>addMore(e)}
                                                                                 className="ml-2 text-white bg-add_btn py-1 px-3 rounded-md text-sm font-bold w-24 cursor-pointer add-more-btn">{t("AddMore")}</div> : "" }
                                                                        </div> : ""}
                                                                    </div>
                                                                </div>}
                                                            </div>)}
                                                    </div>
                                                </div>)}
                                            </div>
                                        </div>
                                    ) : ""}
                                    {question.type === "upload-request" && !question.probabilities.length ? (
                                        <div className="my-2 upload-box">
                                            {question.hyperlink && <div className="flex-start doc-block">
                                                <Link to={question.hyperlink}><div className="text-blue-400 font-bold">{t("OpenDocument")}</div></Link>
                                                {editMode && <div onClick={(e)=>handleDeleting(e, question._id)} className="bg-red-400 hover:bg-red-500 px-3 py-1 rounded-md text-white cursor-pointer ml-2 text-sm delete-doc">{t("Delete")}</div>}
                                                {editMode && <div onClick={(e)=>handleRestoring(e, question._id)} className="bg-green-400 hover:bg-green-500 px-3 py-1 rounded-md text-white cursor-pointer ml-2 text-sm restore-doc hidden">{t("Restore")}</div>}
                                            </div>}
                                            {question && <div data-question_id={question._id} data-qtype={question.type}
                                                              className={`${question.hyperlink ? "hidden upload-inner" : "block"} border bg-gray-100 w-full p-8 border-red-400 rounded-md`} >
                                                <div className="flex-center upl relative">
                                                    <input id={uuid()}
                                                           className="opacity-0 absolute w-full p-10"
                                                           type="file"
                                                           accept="image/jpeg,image/jpg,image/png,.pdf,.docx,.doc" onChange={(e) => addDocumentButtonHandler(e, question._id)}
                                                    />
                                                    <AddButton disabled={!editMode} className={"text-sm"} btn_text={"Upload Document"}/>
                                                    <div className="file-name ml-2"></div>
                                                </div>
                                            </div>}
                                        </div>
                                    ) : ""}
                                    {question.type === "free" && !question.probabilities.length ? (
                                        <div className="my-4">
                                            <input readOnly={!editMode} defaultValue={question.other_text} data-question_id={question._id} data-qtype={question.type} type="text" className="question-free classic-input"/>
                                        </div>
                                    ) : ""}
                                </div>)
                            }
                        </div>)
                    }
            </div>)}
        </div>
    )
}
export default Question;
