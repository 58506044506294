import React from 'react';
import { CSSTransition } from 'react-transition-group';
const Alert = props =>{

    return (
        <CSSTransition
            in={(!!props.message || (!!props.error))}
            mountOnEnter
            unmountOnExit
            timeout={200}
            classNames="modal"
        >
            <div className={` flex items-center justify-center ${props.style}`}>
            { props.error && (<div className="mx-auto my-4 bg-red-300 rounded-md text-white p-4 flex items-center justify-start">
                <img src="/icons/alert.svg" alt=""/>
                <span className="ml-2">{props.error}</span>
            </div>)}
            { props.message && (<div className="mx-auto my-4 bg-green-300 rounded-md text-white p-4 flex items-center justify-start">
                <img src="/icons/alert.svg" alt=""/>
                <span className="ml-2 px-4">{props.message}</span>
            </div>)}
        </div>
        </CSSTransition>
    );
}
export default Alert;