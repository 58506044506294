import React from "react";
import {t} from "i18next";

const EmptyQuestionWidget = () => {

    return (
        <>

                <div className="document-item my-2 bg-card_bg rounded-md p-4 text-sm">
                    <div className="flex items-center justify-between">
                        <div className="flex-center">

                            <div>
                                <div className="text-sm text-red-400">{t("Language")}</div>
                                <div className="text-gray-600 font-bold lg:text-base text-sm mr-1">{t("FutureQuestionnaire")}</div>
                            </div>
                        </div>

                        <div className="w-2/10 flex-center">

                        </div>
                    </div>
                </div>

        </>
    )
}

export default EmptyQuestionWidget;
