import {t} from "i18next";
import DocumentActions from "./DocumentActions";

const DocumentsHeader = (props) => {
    return (
        <div className="mb-4">
            <div className="relative">
                <img className="absolute top-4 left-4 w-3" src="/icons/search.svg" alt=""/>
                <input onKeyUp={(e)=>{props.searchHandler(e.target.value)}} type="text" placeholder={t("Search")} className="pl-10 classic-input"/>
            </div>
            <div className="mt-4 text-sm text-gray-600">
                {!props.setSubClientId && <div className="mb-2">{t("FilterBy")}</div>}
                <DocumentActions subClientId={props.subClientId} subClients={props.subClients} setSubClientId={props.setSubClientId} btn_text={props.action_text || "Add Documents"} btn_action={props.btn_action}/>
            </div>
        </div>
    )
}
export default DocumentsHeader;
