import React, {useContext, useEffect, useState} from 'react';
import { AuthContext } from "../../context/auth-context";
import {t} from "i18next";
import Layout from "../../layouts/Layout";
import LoadingPageSpinner from "../../components/Loading/LoadingPageSpinner";
import {surveyApi} from "../surveys/api/api";
import SurveyWidget from "../../components/Survey/SurveyWidget";
import EmptySurveyWidget from "../../components/Survey/EmptySurveyWidget";
import {documentsApi} from "../documents/api/api";
import DocumentWidget from "../../components/Documents/DocumentWidget";
import EmptyDocumentWidget from "../../components/Documents/EmptyDocumentWidget";
import {questionsApi} from "../questions/api/api";
import SurveyActions from "../../components/Survey/SurveyActions";
import QuestionsWidget from "../../components/Questions/QuestionsWidget";
import EmptyQuestionWidget from "../../components/Questions/EmptyQuestionWidget";

const Dashboard = () => {

    const auth = useContext(AuthContext);
    let name = "Guest.";
    if(auth.userData && auth.userData.name){
        name = auth.userData.name.firstname + " " +auth.userData.name.lastname;
    }

    const { getSurveysRequest, isLoading } = surveyApi();
    const [loadedSurveys, setLoadedSurveys] = useState();
    useEffect(() => {
        const fetchSurveys = async () => {
            try {
                const responseData = await getSurveysRequest();
                let items = responseData.data;
                if(items && items.length){
                    items = items.splice(0, 4);
                    setLoadedSurveys(items);
                }
            } catch (err) {
                console.log(err);
            }
        };
        fetchSurveys();
    }, []);

    const { getSubClientAssignedDocumentsRequest, downloadDocumentRequest } = documentsApi();
    const [loadedDocuments, setLoadedDocuments] = useState();
    useEffect(() => {
        const fetchDocuments = async () => {
            try {
                const responseData = await getSubClientAssignedDocumentsRequest();
                let items = responseData.data.result;
                if(items && items.length){
                    items = items.splice(0, 4);
                    setLoadedDocuments(items);
                }
            } catch (err) {
                console.log(err);
            }
        };
        fetchDocuments();
    }, []);

    const downloadHandler = async (_id) => {

        try{
            const response = await downloadDocumentRequest(_id);
            const url = response.data;
            window.open(
                url,
                '_blank'
            );
        }catch (e){}

    }

    const { getQuestions,  error, clearError } = questionsApi();
    const [loadedQuestions, setLoadedQuestions] = useState([]);

    useEffect(() => {
        const fetchQuestions = async () => {
            try {
                const responseData = await getQuestions();
                const items = responseData.data;
                setLoadedQuestions(items);
            } catch (err) {
                console.log(err);
            }
        };
        fetchQuestions();

    }, []);

    return (
        <Layout>
            {isLoading && (<LoadingPageSpinner/>)}
            <div className="p-5">
                {/*<div className="flex-between">*/}
                {/*    <div className="font-bold text-xl flex-center">*/}
                {/*        History*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className="flex-between mt-6 mb-4">
                    <div className="font-bold text-xl flex-center">
                        <img className="mr-2" src="/icons/surveys-active.svg" alt=""/>
                        Surveys
                    </div>
                    <a href={"/surveys"} className="font-bold text-blue-400 text-sm cursor-pointer">{t('ViewAll')}</a>
                </div>

                {loadedSurveys && (
                    <SurveyWidget items={loadedSurveys}/>
                )}
                {!loadedSurveys && (
                    <div>
                        <EmptySurveyWidget/>
                        <EmptySurveyWidget/>
                    </div>
                )}

                <div className="mt-6">
                    <div>
                        <div>
                            <div className="flex-between mt-6 mb-4">
                                <div className="font-bold text-xl flex-center">
                                    <img className="mr-2" src="/icons/docs.svg" alt=""/>
                                    {t('Questions')}
                                </div>
                                <a href={"/questions"} className="font-bold text-blue-400 text-sm cursor-pointer">{t('ViewAll')}</a>
                            </div>
                            {loadedQuestions.length ? (
                                <QuestionsWidget items={loadedQuestions}/>
                            ): <div>
                                <EmptyQuestionWidget/>
                                <EmptyQuestionWidget/>
                            </div>}
                        </div>
                    </div>
                    <div>
                        <div>
                            <div className="flex-between mt-6 mb-4">
                                <div className="font-bold text-xl flex-center">
                                    <img className="mr-2" src="/icons/docs.svg" alt=""/>
                                    {t('Documents')}
                                </div>
                                <a href={"/documents"} className="font-bold text-blue-400 text-sm cursor-pointer">{t('ViewAll')}</a>
                            </div>
                            {loadedDocuments && (
                                <DocumentWidget downloadHandler={downloadHandler} items={loadedDocuments}/>
                            )}
                            {!loadedDocuments && (
                                <div>
                                    <EmptyDocumentWidget/>
                                    <EmptyDocumentWidget/>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

        </Layout>);
};
export default Dashboard;
