import {t} from "i18next";
import ChangeLang from "../Lang/ChangeLang";

const Header = (props) => {
    const showSidebar = () => {
        document.querySelector('.sidebar').classList.toggle('hidden');
    }
    return (
        <div className="h-height_header flex items-start justify-between flex-wrap md:flex-nowrap mb-10 md:mb-0">
            <div className="flex-start">
                <div className="mr-2 border border-2 border-grey-600 cursor-pointer lg:hidden p-2 rounded" onClick={showSidebar}>
                    <img className="w-6" src="/icons/menu.svg" alt=""/>
                </div>
                <div className="flex items-center justify-start">
                    <div><img className="h-full" src="/icons/line-vertical.svg" alt=""/></div>
                    <div className="ml-2 text-gray-400">
                        <div>{t("ClientPortal")}</div>
                        <div className="font-bold text-3xl">{t(props.title)}</div>
                    </div>
                </div>
            </div>
            <div className="flex items-center justify-center mb:mt-0 mt-4">
                <div className="flex items-center justify-center mr-4">
                    <div className="mx-2 bg-icon_bg rounded-full p-4">
                        <img className="w-4 h-4" src="/icons/user.svg" alt=""/>
                    </div>
                    <div className="text-gray-600 text-sm">
                        <div>{t("Welcome")}</div>
                        <div>{props.user_name}</div>
                    </div>
                </div>
                <div className="mx-4 bg-icon_bg rounded-full p-4">
                    <img className="w-4 h-4" src="/icons/bell.svg" alt=""/>
                </div>
                <ChangeLang/>
            </div>
        </div>
    )
}
export default Header;
