export const helpers = () => {
    const transformDate = date => {
        if(date){
            const dateSplit = date.split('T');
            if(dateSplit.length > 1){
                return dateSplit[0];
            }
            return date;
        }
    }

    const transformTime = date => {
        if(date){
            const dateSplit = date.split('T');
            if(dateSplit.length > 1){
                return dateSplit[1].slice(0, 5);
            }
            return date;
        }
    }

    const strLimit = (str, limit=50) => {
        return (str.length > limit) ? str.slice(0, limit-1) + '...' : str;
    }

    const setSubClients = (item)=>{
        let patient = [];
        patient['id'] = item._id;
        patient['sub'] = [];
        item.subclients.forEach((i)=>{
            let a = [];
            a['id'] = i._id;
            a['name'] = i.relation.category_id.label;
            patient['sub'].push(a);
        });
        window.patient = patient;
    }

    const shorterString = (string, len=30) =>{
        if(string && string.length > len){
            string = string.substring(0, len-1) + "...";
        }
        return string;
    }

    return {transformDate, strLimit, setSubClients, shorterString, transformTime};
}
