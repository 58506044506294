import React, {useEffect, useState} from 'react';
import Layout from "../../layouts/Layout";
import LoadingPageSpinner from "../../components/Loading/LoadingPageSpinner";
import PageHeader from "../../components/PageHeader/PageHeader";
import Alert from "../../components/Alert/Alert";
import {questionsApi} from "./api/api";
import QuestionsWidget from "../../components/Questions/QuestionsWidget";
import {t} from "i18next";
import SurveyActions from "../../components/Survey/SurveyActions";

const Questions = () => {
    const { getQuestions, isLoading,  error, clearError } = questionsApi();
    const [loadedQuestions, setLoadedQuestions] = useState([]);
    const [lang, setLang] = useState('fr');
    useEffect(() => {
        const fetchQuestions = async () => {
            try {
                const responseData = await getQuestions(lang);
                const items = responseData.data;
                setLoadedQuestions(items);
            } catch (err) {
                console.log(err);
            }
        };
        fetchQuestions();

    }, [lang]);
    return (
        <Layout>
            <Alert error={error}/>
            {isLoading && (<LoadingPageSpinner/>)}
            {loadedQuestions && (
            <div className="p-4">
                <SurveyActions onchange={(e) => setLang(e.target.value)}/>
                <QuestionsWidget items={loadedQuestions}/>
            </div>)}
            {!loadedQuestions.length && (<div className="text-center text-gray-500">{t("QuestionsNotFound")}</div>)}
        </Layout>);
};
export default Questions;
