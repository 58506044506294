import Logo from "../Logo/Logo";
import variables from "../../constants/variables";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {t} from "i18next";
import {AuthContext} from "../../context/auth-context";
import {useContext} from "react";

const Sidebar = ()=>{
    const routeName = useLocation();
    const auth = useContext(AuthContext);
    const navigate = useNavigate();
    const hideSidebar = () => {
        document.querySelector('.sidebar').classList.toggle('hidden');
    }
    return (
        <div className="w-full lg:w-width_xs p-4 lg:block hidden sidebar absolute lg:static z-50 bg-card_bg h-screen">
            <div className="h-height_header">
                <Logo type={"horizontal"} style={"lg:w-2/3 flex-center lg:block"}></Logo>
            </div>
            <div className='absolute top-2 right-2 lg:hidden cursor-pointer' onClick={hideSidebar}>
                <img src="/icons/close_.svg" alt=""/>
            </div>
           <div className="lg:block flex flex-col items-center">
               {variables.navigation.map(item =>
                   <Link key={item.title} to={item.route}>
                       <div className={`mb-8 flex items-center justify-start font-bold cursor-pointer ${routeName.pathname === item.route ? '' : 'text-gray-500'}`}>
                           <img className="mr-2" src={`/icons/${item.icon}${routeName.pathname === item.route ? '-active' : ''}.svg`} alt=""/>
                           <div>{t(item.title)}</div>
                       </div>
                   </Link>
               )}
           </div>
            <div onClick={()=>{auth.logout(); navigate("/login");}} className="text-center lg:text-left text-red-500 cursor-pointer">{t("SignOut")}</div>
        </div>
    )
}

export default Sidebar;
