import variables from "../../constants/variables";
import React from "react";
import { v4 as uuid } from 'uuid';
const Pagination = (props) => {

   const pageCount = props.pageCount;
   const currentPage = props.currentPage;
   const setCurrentPage = props.setCurrentPage;
   const maxPaginationButtons = 3;

   if(pageCount == 1){
       return "";
   }
    const prevPage = () => {
        if(currentPage > 1 ){setCurrentPage(currentPage-1)}
    }
    const nextPage = () => {
        if(currentPage < pageCount){setCurrentPage(currentPage+1)}
    }
    const pageHandler = (page) => {
        setCurrentPage(page);
    }

    return (
        <div className="flex items-center text-sm">
            {pageCount > 1 ? <img onClick={prevPage} src="/icons/arrow-t-left.svg" className="mr-2 cursor-pointer" alt=""/> : "" }
            {[...Array(pageCount)].map((x, i) =>
                (i === maxPaginationButtons && pageCount > 6) ? (currentPage === 3 || currentPage === 5 || currentPage === 4 ?
                        <>
                            <div onClick={()=>{pageHandler(i+1)}} className={`${currentPage == 4 ? 'bg-blue-400' : 'bg-gray-300'} 
                            px-2 py-1 cursor-pointer rounded-md mr-2 text-white`} key={uuid()}>
                                4
                            </div>
                            <div key={uuid()} className="px-1 text-gray-500 mr-2">...</div>
                        </> : <div key={uuid()} className="px-1 text-gray-500 mr-2">...</div>) :
                        ((i+1) <= maxPaginationButtons || (i+1) > pageCount - maxPaginationButtons ||
                        (currentPage > maxPaginationButtons && pageCount > maxPaginationButtons * 2
                            && (((i+1) >= currentPage && (i+1) <= currentPage + maxPaginationButtons))
                            || (i<=currentPage && i>= currentPage-maxPaginationButtons))) ?

                            <div onClick={()=>{pageHandler(i+1)}} className={`${currentPage == (i+1) ? 'bg-blue-400' : 'bg-gray-300'} 
                            px-2 py-1 cursor-pointer rounded-md mr-2 text-white`} key={uuid()}>
                                {i+1}
                            </div>
                        : ""
            )}
            {pageCount > 1 ? <img onClick={nextPage} src="/icons/arrow-t-right.svg" className="cursor-pointer" alt=""/> : "" }
        </div>
    )
}

export default Pagination;
