import { useHttpClient } from "../../../hooks/http-hook";
import {useContext} from "react";
import {AuthContext} from "../../../context/auth-context";
import variables from "../../../constants/variables";


export const documentsApi = () => {
    const auth = useContext(AuthContext);
    const { isLoading, sendRequest, error, clearError } = useHttpClient();
    const getSubClientAssignedDocumentsRequest = async () => {
        let url = `/documents/assign`;

        return await sendRequest(
            url,
            'GET',
            undefined,
            {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token
            },
            true
        )
    }

    const deleteProbabilityDocument = async (probability_id) => {
        return await sendRequest(
            "/documents/subclient?&probability_id="+probability_id,
            'DELETE',
            undefined,
            {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token
            }
        )
    }

    const uploadDocumentsRequest = async (data) => {
        return await sendRequest(
            "/documents",
            'POST',
            data,
            {
                // 'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token
            }
        )
    }

    const getDocumentsRequest = async (page, search, subclient_id="") => {
        let url = `/documents?page=${page}&limit=${variables.maxDocumentsPagination}`;

        if(search){
            url += `&search_text=${search}`
        }
        if(subclient_id){
            url += `&subclient_id=${subclient_id}`
        }
        return await sendRequest(
            url,
            'GET',
            undefined,
            {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token
            },
            true
        )
    }
    const getSubClientDocumentsRequest = async (_id, search) => {
        let url = `/documents/subclient?subclient_id=${_id}`;

        return await sendRequest(
            url,
            'GET',
            undefined,
            {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token
            },
            true
        )
    }


    const deleteSubClientDocumentRequest = async (_id, search) => {
        let url = `/documents/assign?_id=${_id}`;

        return await sendRequest(
            url,
            'DELETE',
            undefined,
            {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token
            }
        )
    }

    const assignDocument = async (data) => {
        let url = `/documents/assign`;

        return await sendRequest(
            url,
            'POST',
            data,
            {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token
            },
            true
        )
    }
    const downloadDocumentRequest = async (_id) => {
        let url = `/documents/generate?_id=${_id}`;

        return await sendRequest(
            url,
            'GET',
            undefined,
            {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token
            }
        )
    }
    const deleteDocumentsRequest = async (id) => {
        return await sendRequest(
            "/documents?_id="+id,
            'DELETE',
            undefined,
            {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token
            }
        )
    }





    const updateDocumentDescription = async (data) => {
        return await sendRequest(
            "/documents/assign",
            'PATCH',
            data,
            {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token
            }
        )
    }

    return { updateDocumentDescription, deleteProbabilityDocument, assignDocument, getDocumentsRequest, deleteDocumentsRequest, uploadDocumentsRequest, downloadDocumentRequest, getSubClientDocumentsRequest, getSubClientAssignedDocumentsRequest, deleteSubClientDocumentRequest, isLoading, error, clearError };
};
