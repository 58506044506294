import AddButton from "../Button/AddButton";
import {t} from "i18next";
import {Link} from "react-router-dom";
import SubClientSelect from "../SubClients/SubclientSelect";

const ClientActions = (props) => {
    return (
        <>
            <div className="flex items-center justify-between">
                <div className="flex items-center">

                    {!props.subClients && <div className="relative w-width_ex mr-3">
                        <select className=" classic-input bg-transparent py-3" name="" id="">
                            <option value="">{t("AddedRecently")}</option>
                        </select>
                        <img className="absolute right-2 top-5" src="/icons/arrow-t-down.svg" alt=""/>
                    </div>}
                    {props.subClients && (<div className="w-44 relative">
                            <img className="absolute right-2 top-11 z-10" src="/icons/arrow-t-down.svg" alt=""/>
                            <div className="text-sm text-gray-500 mb-1">{t("ChooseClient")}</div>
                        <SubClientSelect subClientId={props.subClientId} subClients={props.subClients} setSubClientId={props.setSubClientId}/>
                        </div>)}
                    {!props.subClients && <div className="relative w-width_ex mr-3">
                        <select className=" classic-input bg-transparent py-3" name="" id="">
                            <option value="">{t("FileType")}</option>
                        </select>
                        <img className="absolute right-2 top-5" src="/icons/arrow-t-down.svg" alt=""/>
                    </div>}
                </div>
                {props.btn_text && <AddButton btn_text={props.btn_text} onclick={props.btn_action} />}
            </div>
        </>
    )
}

export default ClientActions;
