const variables = {
    verificationCodeLength: 6,
    maxClientPagination: 3,
    maxDocumentsPagination: 10,
    maxUploadFiles: 10,
    navigation: [
        {
            title: "Dashboard",
            icon: "dashboard",
            route: "/dashboard"
        },
        {
            title: "Surveys",
            icon: "surveys",
            route: "/surveys"
        },
        {
            title: "Documents",
            icon: "documents",
            route: "/documents"
        },
        {
            title: "Questions",
            icon: "documents",
            route: "/questions"
        }
    ],
    langs: [
        {
            title: "ENG",
            value: "en",
        },
        {
            title: "ESP",
            value: "es",
        },
        {
            title: "FRN",
            value: "fr",
        },
        {
            title: "RUS",
            value: "ru",
        }
    ],
    langName: {
        "fr": "French",
        "en": "English",
        "ru": "Russian",
        "es": "Spanish",
    }
}
export default variables;
