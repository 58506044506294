import React, {useContext} from "react";
import {Link} from "react-router-dom";
import {AuthContext} from "../../context/auth-context";
import {t} from "i18next";

const NotFound = () => {
    const auth = useContext(AuthContext);
    return (
        <>
            {
                !auth.isLoggedIn && (
                    <div className="flex-center" style={{marginTop: "44vh"}}>

                        <div>
                            <div className="text-blue-400 text-7xl font-bold mb-4 text-center">404</div>
                            <Link to="/dashboard">
                                <div className="bg-blue-400 rounded-md px-4 py-2 hover:bg-blue-500 text-white font-bold">
                                    {t("GotoDashboard")}
                                </div>
                            </Link>
                        </div>
                    </div>
                )
            }
        </>

    );
}
export default NotFound;
