import React, { useContext, useEffect, useState } from "react";
import {
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH,
  VALIDATOR_REQUIRE,
} from "../../utils/validation/validators";
import { AuthContext } from "../../context/auth-context";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { useForm } from "../../hooks/form-hook";
import { authApi } from "./api/api";
import Input from "../../components/Input/Input";
import Alert from "../../components/Alert/Alert";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import PublicLayout from "../../layouts/PublicLayout";

const Auth = () => {
  const auth = useContext(AuthContext);

  const navigate = useNavigate();
  if (auth.token && !auth.patient_id) {
    navigate("/patient/define");
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const refreshParam = urlParams.get("refresh");
    const secretKeyParam = urlParams.get("secret-key");

    try {
      if (secretKeyParam) {
        auth.logout();
        auth.saveSecretKey(secretKeyParam);
      }
    } catch (err) {
      console.log(err);
    }

    const refreshToken = async (refresh) => {
      try {
        const res = await refreshTokenRequest(
          JSON.stringify({ refresh_token: refresh })
        );
        // auth.updateToken(res.data.access_token)
        auth.login(
          res.data.user._id,
          res.data.user,
          res.data.token,
          res.data.refresh_token
        );
      } catch (err) {
        console.log(err);
      }
    };

    if (refreshParam) {
      refreshToken(refreshParam).then(() => {
        console.log("done");
      });
    }
  }, []);

  const [isEmailMode, setIsEmailMode] = useState(true);
  const [formState, inputHandler, setFormData] = useForm(
    {
      email: {
        value: "",
        isValid: false,
      },
    },
    false
  );
  const { loginRequest, refreshTokenRequest, isLoading, error, clearError } =
    authApi();

  const switchModeHandler = () => {
    clearError();
    if (!isEmailMode) {
      setFormData(
        {
          email: {
            value: "",
            isValid: false,
          },
        },
        false
      );
    } else {
      setFormData(
        {
          phone_number: {
            value: "",
            isValid: false,
          },
        },
        false
      );
    }
    setIsEmailMode((prevMode) => !prevMode);
  };

  const authSubmitHandler = async (event) => {
    event.preventDefault();
    clearError();
    let data = isEmailMode
      ? {
          username: formState.inputs.email.value,
        }
      : {
          username: formState.inputs.phone_number.value,
        };
    data = JSON.stringify(data);
    const url = isEmailMode ? "/verification/email" : "/verification/phone";
    try {
      const responseData = await loginRequest(data);
      auth.verify({
        userId: responseData.data.user_id,
        sendData: data,
        redirect: url,
      });
      navigate(url);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <PublicLayout>
      <Alert style={"h-24"} error={error} />
      <div className="flex font-classic">
        <div className="classic-card relative">
          <p className="text-sm font-bold">{t("WelcomeToAccount")}</p>
          <form onSubmit={authSubmitHandler}>
            {isEmailMode && (
              <React.Fragment>
                <Input
                  styles="classic-input"
                  marginStyles="my-4"
                  placeholder={t("EnterEmail")}
                  element="input"
                  id="email"
                  type="email"
                  label={t("Email")}
                  validators={[VALIDATOR_EMAIL()]}
                  errorText={t("ValidEmail")}
                  onInput={inputHandler}
                />
              </React.Fragment>
            )}
            {!isEmailMode && (
              <Input
                styles="classic-input"
                marginStyles="my-4"
                placeholder="000 000 000"
                element="input"
                id="phone_number"
                type="text"
                label="Phone number"
                validators={[VALIDATOR_MINLENGTH(9)]}
                errorText={t("ValidPhone")}
                onInput={inputHandler}
              />
            )}
            <div
              onClick={switchModeHandler}
              className="cursor-pointer hover:text-gray-600 font-classic my-2 text-md text-gray-500"
            >
              {isEmailMode ? t("SignInWithPhone") : t("SignInWithEmail")}
            </div>
            <button
              className="w-full classic-btn"
              type="submit"
              disabled={!formState.isValid}
            >
              <div className="relative">
                <LoadingSpinner isLoading={isLoading} />
                <span>{t("SignIn")}</span>
              </div>
            </button>
          </form>
        </div>
      </div>
    </PublicLayout>
  );
};

export default Auth;
