import { useHttpClient } from "../../../hooks/http-hook";
import {useContext} from "react";
import {AuthContext} from "../../../context/auth-context";


export const surveyApi = () => {
    const auth = useContext(AuthContext);
    const { isLoading, sendRequest, error, clearError } = useHttpClient();
    const getSurveysRequest = async ( lang) => {
        let url = "/surveys/assign?language="+lang;
        return await sendRequest(
            url,
            'GET',
            undefined,
            {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token
            },
            true
        )
    }

    const getSurveyRequest = async (id) => {
        return await sendRequest(
            `/answers/assign?assign_id=${id}`,
            'GET',
            undefined,
            {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token
            }
        )
    }

    const submitSurveyRequest = async (data) => {
        return await sendRequest(
            '/answers/assign',
            'POST',
            data,
            {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token
            }
        )
    }





    const getSubClientSurveys = async (id) => {
        return await sendRequest(
            `/surveys/assign?subclient_id=${id}`,
            'GET',
            undefined,
            {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token
            }
        )
    }
    const getSubClientSurvey = async (assign_id) => {
        return await sendRequest(
            // `/answers/assign?subclient_id=${subclient_id}&survey_id=${survey_id}`,
            `/answers/assign?assign_id=${assign_id}`,
            'GET',
            undefined,
            {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token
            }
        )
    }

    const deleteSubClientSurvey = async (id) => {
        return await sendRequest(
            `/surveys/assign?_id=${id}`,
            'DELETE',
            undefined,
            {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token
            }
        )
    }



    const assignSurveyRequest = async (data) => {
        return await sendRequest(
            '/surveys/assign',
            'POST',
            data,
            {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token
            }
        )
    }
    const addComment = async (data) => {

        // {
        //     "survey_assign_id": "640db78a93e37e2e11347c31",
        //     "description": "A test comment from Juliano"
        // }
        return await sendRequest(
            '/surveys/comments',
            'POST',
            data,
            {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token
            }
        )
    }

    const getComments = async (id) => {
        return await sendRequest(
            `/surveys/comments?survey_assign_id=${id}`,
            'GET',
            undefined,
            {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token
            }
        )
    }

    const deleteComment = async (id) => {

        return await sendRequest(
            "/surveys/comments?_id="+id,
            'DELETE',
            undefined,
            {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token
            }
        )
    }

    const editComment = async (data) => {

        return await sendRequest(
            "/surveys/comments",
            'PATCH',
            data,
            {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token
            }
        )
    }

    const submitReminder = async (data, method) => {

        return await sendRequest(
            "/reminders",
            method,
            data,
            {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token
            }
        )
    }

    const deleteReminder = async (data) => {

        return await sendRequest(
            "/reminders",
            'PATCH',
            data,
            {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token
            }
        )
    }

    const getReminder = async (id) => {
        return await sendRequest(
            `/reminders?subclient_id=${id}`,
            'GET',
            undefined,
            {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token
            }
        )
    }


    return { getSurveysRequest, getSurveyRequest, getSubClientSurveys, getSubClientSurvey, submitSurveyRequest, assignSurveyRequest, deleteSubClientSurvey,
        addComment, getComments, deleteComment, editComment, submitReminder, getReminder, deleteReminder, isLoading, error, clearError };
};
