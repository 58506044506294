import {Link, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import LoadingPageSpinner from "../../components/Loading/LoadingPageSpinner";
import Layout from "../../layouts/Layout";
import Survey from "../../components/Survey/Survey";
import Back from "../../components/Button/Back";
import {questionsApi} from "../../modules/questions/api/api";
import Question from "./Question";
import {t} from "i18next";

const QuestionDetails = () => {
    const { getSubClientAnswers, getQuestion, isLoading, error, clearError } = questionsApi();
    const [loadedQuestion, setLoadedQuestion] = useState();
    const assign_id = useParams().assign_id;
    if (!assign_id) {
        return 404;
    }
    useEffect(() => {
        const fetchQuestion = async () => {
            try {
                let responseData = await getSubClientAnswers(assign_id);
                const item = responseData.data;
                setLoadedQuestion(item);
            } catch (err) {
                console.log(err);
            }
        };
        fetchQuestion();

    }, []);

    return (
        <Layout>
            {(isLoading) && (<LoadingPageSpinner />)}
            <Link to={'/dashboard'} className="absolute flex-center cursor-pointer" style={{"top": "-30px"}}>
                <img src="/icons/arrow-back.svg" alt=""/>
                <div className="ml-2 text-sm text-gray-400">{t("GoBack")}</div>
            </Link>
            <Question subclientId={true} assign_id={assign_id} loadedQuestion={loadedQuestion}/>
        </Layout>
    )
}
export default QuestionDetails;
