import { useHttpClient } from "../../../hooks/http-hook";
import {useContext} from "react";
import {AuthContext} from "../../../context/auth-context";

export const questionsApi = () => {
    const auth = useContext(AuthContext);
    const { isLoading, sendRequest, error, clearError } = useHttpClient();

    const getQuestions = async (lang) => {
        let url = `/questions/assign?language=`+lang;

        return await sendRequest(
            url,
            'GET',
            undefined,
            {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token
            },
            true
        )
    }

    const getSubClientAnswers = async (assign_id) => {
        return await sendRequest(
            `/questions/answers?assign_id=${assign_id}`,
            'GET',
            undefined,
            {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token
            }
        )
    }




    const getQuestion = async (id) => {

        let url = `/question?_id=${id}`;

        return await sendRequest(
            url,
            'GET',
            undefined,
            {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token
            }
        )
    }
    const assignQuestionRequest = async (data) => {
        return await sendRequest(
            '/questions/assign',
            'POST',
            data,
            {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token
            }
        )
    }

    const getSubClientQuestions = async (id) => {
        return await sendRequest(
            `/questions/assign?subclient_id=${id}`,
            'GET',
            undefined,
            {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token
            },
            true
        )
    }
    const getQuestionsWithAssignedFlag = async (id) => {
        return await sendRequest(
            `/questions?subclient_id=${id}`,
            'GET',
            undefined,
            {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token
            }
        )
    }



    const submitQuestion = async (data) => {
        return await sendRequest(
            '/questions/answers',
            'POST',
            data,
            {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token
            }
        )
    }



    return { getSubClientQuestions, getQuestionsWithAssignedFlag, getSubClientAnswers, getQuestions, submitQuestion, assignQuestionRequest, getQuestion, isLoading, error, clearError };
};
