import React, {useReducer, useEffect} from 'react';
import {validate} from "../../utils/validation/validators";

const inputReducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE':
      return {
        ...state,
        value: action.val,
        isValid: validate(action.val, action.validators)
      };
    case 'TOUCH': {
      return {
        ...state,
        isTouched: true
      }
    }
    default:
      return state;
  }
};
const Input = props => {

  const [inputState, dispatch] = useReducer(inputReducer, {
    value: props.initialValue || '',
    isTouched: false,
    isValid: props.initialValid || false
  });

  const {id, onInput} = props;
  const {value, isValid} = inputState;

  useEffect(() => {
    onInput(id, value, isValid)
  }, [id, value, isValid, onInput])

  const changeHandler = event => {
    dispatch({
      type: 'CHANGE',
      val: event.target.value,
      validators: props.validators
    });
  };

  const touchHandler = () => {
    dispatch({
      type: 'TOUCH'
    });
  };

  const element =
      props.element === 'input' ? (
          <input className={props.styles}
              id={props.id}
              type={props.type}
              placeholder={props.placeholder}
              onChange={changeHandler} onKeyUp={props.onKeyup} onFocus={props.onFocus}
              onBlur={touchHandler}
              value={inputState.value}
          />
      ) : props.element === 'select' ?
          (
              <select
                  className={props.styles}
                  id={props.id}
                  onChange={changeHandler}
                  onBlur={touchHandler}
                  value={inputState.value}
              >
                {props.selectOptions}
              </select>
          ) :
          (
          <textarea
              className={props.styles}
              id={props.id}
              rows={props.rows || 3}
              onChange={changeHandler}
              onBlur={touchHandler}
              value={inputState.value}
          />
      );

  return (
      <>
          <div className={props.marginStyles}>
            <label className="block text-sm text-gray-500" htmlFor={props.id}>{props.label}</label>
            {element}
            {!inputState.isValid && inputState.isTouched && <p className="text-sm text-red-500">{props.errorText}</p>}
          </div>
      </>
  );
};

export default Input;
