import variables from "../../constants/variables";
import {useTranslation} from "react-i18next";

const ChangeLang = () => {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;
    return (
        <div className="flex items-center">
            <img className="mr-2" src="/icons/globe.svg" alt=""/>
            <div className="relative mr-3 w-16">
                <select onChange={(e)=>window.changeLanguage(e.target.value)} name="" id=""
                        className="w-16 text-gray-600 text-md bg-transparent focus:outline-none cursor-pointer">
                    {variables.langs.map(item =>
                        <option selected={item.value === currentLanguage} key={item.value} value={item.value}>{item.title}
                        </option>
                    )}
                </select>
                <img className="absolute right-2 top-2" src="/icons/arrow-t-down.svg" alt=""/>
            </div>
        </div>
    )
}

export default ChangeLang;
