import ReactDOM from "react-dom";
import {t} from "i18next";
import {Link} from "react-router-dom";

const Tabs = (props) => {
    const subclient_id = props.subclient_id ? '/'+props.subclient_id : "";
    return (
        <div className=" bg-bar_bg rounded-t-md flex items-center justify-between">
            <Link to={`/clients/details-tab/${props.patient_id}`}>
                <div className={`text-white border-blue-500 p-4 cursor-pointer ${props.active_tab === "info" ? "border-b-4": ""}`}>{t("Information")}</div>
            </Link>
           <Link to={`/clients/surveys-tab/${props.patient_id}${subclient_id}`}>
               <div className={`text-white border-blue-500 p-4 cursor-pointer ${props.active_tab === "surveys" ? "border-b-4": ""}`}>{t("Surveys")}</div>
           </Link>
            <Link to={`/clients/questions-tab/${props.patient_id}${subclient_id}`}>
                <div className={`text-white border-blue-500 p-4 cursor-pointer ${props.active_tab === "questions" ? "border-b-4": ""}`}>{t("GeneralQuestions")}</div>
            </Link>
            <Link to={`/clients/documents-tab/${props.patient_id}${subclient_id}`}>
                <div className={`text-white border-blue-500 p-4 cursor-pointer ${props.active_tab === "documents" ? "border-b-4": ""}`}>{t("SubClientDocuments")}</div>
            </Link>
            <Link to={`/clients/documents-tab/admin/${props.patient_id}${subclient_id}`}>
                <div className={`text-white border-blue-500 p-4 cursor-pointer ${props.active_tab === "documents-admin" ? "border-b-4": ""}`}>{t("AdminDocuments")}</div>
            </Link>
            <Link to={`/clients/graphs-tab/${props.patient_id}${subclient_id}`}>
                <div className={`text-white border-blue-500 p-4 cursor-pointer ${props.active_tab === "graphs" ? "border-b-4": ""}`}>{t("Graphs")}</div>
            </Link>
        </div>
    );
}
export default Tabs;
