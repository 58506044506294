import { createContext } from "react";

export const AuthContext = createContext({
  isLoggedIn: false,
  userId: null,
  token: null,
  refresh_token: null,
  verifyData: null,
  userData: null,
  patient_id: null,
  secret_key: null,
  login: () => {},
  logout: () => {},
  verify: () => {},
  savePatientID: () => {},
  saveSecretKey: () => {},
});
