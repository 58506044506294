import React, { useContext, useEffect, useRef, useState } from "react";
import Alert from "../../components/Alert/Alert";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import { t } from "i18next";
import PublicLayout from "../../layouts/PublicLayout";
import { AuthContext } from "../../context/auth-context";
import { useNavigate } from "react-router-dom";
import { authApi } from "./api/api";

const DefinePatient = () => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const [message, setMessage] = useState(null);
  const { getPatientIdRequest, isLoading, error, clearError } = authApi();
  const secretRef = useRef();

  useEffect(() => {
    if (auth.secret_key) {
      getPatientFromSecret(auth.secret_key);
    }
  }, []);

  if (auth.token && auth.patient_id) {
    navigate("/dashboard");
  }
  if (!auth.token && !auth.patient_id) {
    navigate("/login");
  }

  const getPatientIdHandler = async (event) => {
    event.preventDefault();
    try {
      clearError();
      //   const res = await getPatientIdRequest(secretRef.current.value);
      //   if (res.success) {
      //     auth.savePatientID(res.data.patient_id);
      //     navigate("/dashboard");
      //   }

      await getPatientFromSecret(secretRef.current.value);
    } catch (err) {
      console.log(err);
    }
  };

  const getPatientFromSecret = async (key) => {
    try {
      const res = await getPatientIdRequest(key);
      if (res.success) {
        auth.savePatientID(res.data.patient_id);
        navigate("/dashboard");
      }
    } catch (err) {
      throw err;
    }
  };

  return (
    <PublicLayout>
      <Alert style={"h-24"} error={error} message={message} />
      <div className="flex font-classic">
        <div className="classic-card py-6">
          <p className="text-center text-sm font-bold mb-3">
            {t("EnterSecretKey")}
          </p>
          <form onSubmit={getPatientIdHandler}>
            <div className="text-sm text-gray-500 mb-1">{t("SecretKey")}</div>
            <input
              className="classic-input mb-2"
              type="text"
              ref={secretRef}
              placeholder={t("SecretKey")}
            />
            <button className="w-full classic-btn mt-4" type="submit">
              <div className="relative">
                <LoadingSpinner isLoading={isLoading} />
                <span>{t("Submit")}</span>
              </div>
            </button>
            <div className="text-center text-gray-600 my-2">or</div>
            <div
              onClick={() => {
                auth.logout();
                navigate("/login");
              }}
              className="text-center text-gray-700 cursor-pointer"
            >
              {t("BackToLogin")}
            </div>
          </form>
        </div>
      </div>
    </PublicLayout>
  );
};

export default DefinePatient;
