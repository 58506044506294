import React, {useState} from 'react';
import {useParams} from "react-router-dom";

export const usePagination = () => {

  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(useParams().page || 1);

  const setCountHandler = (total, count) => {
    if(total > 0){
      let lastPage = Math.ceil(total / count);
      console.log("total" + total);
      console.log("count" + count);
      console.log("page" + lastPage);
      setPageCount(lastPage);
    }
  }
  return {currentPage, setCurrentPage, pageCount, setCountHandler};
};