import Logo from "../components/Logo/Logo";
import Footer from "../components/Footer/Footer";
import ChangeLang from "../components/Lang/ChangeLang";

const PublicLayout = ({children}) => {
    return (
        <>
            <div className="flex items-center justify-end m-4">
                <ChangeLang/>
            </div>
            <Logo style={"pt-16 flex items-center justify-center"}/>
                <main>{children}</main>
            <Footer/>
        </>
    )
}
export default PublicLayout;