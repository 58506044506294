import { useState, useCallback, useEffect } from "react";

export const useAuth = () => {
  const [token, setToken] = useState(false);
  const [refresh_token, setRefreshToken] = useState(false);
  const [userId, setUserId] = useState(false);
  const [userData, setUserData] = useState(false);
  const [verifyData, setVerifyData] = useState(false);
  const [patient_id, setPatientId] = useState(null);
  const [secret_key, setSecretKey] = useState(null);

  const login = useCallback((uid, data, token, refresh_token) => {
    setToken(token);
    setRefreshToken(refresh_token);
    setUserId(uid);
    setUserData(data);
    localStorage.setItem(
      "userData",
      JSON.stringify({
        userId: uid,
        userData: data,
        token: token,
        refresh_token: refresh_token,
      })
    );
  }, []);

  const saveSecretKey = useCallback((key) => {
    setSecretKey(key);
  }, []);

  const updateToken = useCallback((token) => {
    setRefreshToken(token);
    const storedData = JSON.parse(localStorage.getItem("userData"));
    storedData.token = token;
    localStorage.setItem("userData", JSON.stringify(storedData));
  }, []);

  const logout = useCallback(() => {
    setToken(null);
    setUserId(null);
    setUserData(null);
    setRefreshToken(null);
    localStorage.removeItem("userData");
    localStorage.removeItem("patient_id");
  }, []);

  const verify = useCallback((data) => {
    setVerifyData(data);
  }, []);

  const savePatientID = useCallback((patient_id) => {
    setPatientId(patient_id);
    localStorage.setItem("patient_id", patient_id);
  }, []);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("userData"));
    if (storedData && storedData.token) {
      login(
        storedData.userId,
        storedData.userData,
        storedData.token,
        storedData.refresh_token
      );
    }
    const storedPatient = localStorage.getItem("patient_id");
    console.log(storedPatient);
    if (storedPatient) {
      setPatientId(storedPatient);
    }
  }, [login]);

  return {
    token,
    refresh_token,
    userId,
    userData,
    login,
    logout,
    verify,
    verifyData,
    updateToken,
    savePatientID,
    patient_id,
    secret_key,
    saveSecretKey,
  };
};
