import {useState, useCallback, useRef, useEffect, useContext} from 'react';
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../context/auth-context";

export const useHttpClient = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const activeHttpRequests = useRef([]);
  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  const sendRequest = useCallback(

    async (url, method = 'GET', body = null, headers = {}, withPatientParam = false) => {
      setIsLoading(true);
      const httpAbortCtrl = new AbortController();
      activeHttpRequests.current.push(httpAbortCtrl);

      try {
        if(withPatientParam){
          url += url.includes('?') ? '&' : '?';
          url += `patient_id=${auth.patient_id}`;

          if(!auth.patient_id){
            navigate('/patient/define');
          }
        }
        const response = await fetch(process.env.REACT_APP_API_URL + url, {
          method,
          body,
          headers,
          signal: httpAbortCtrl.signal
        });

        const responseData = await response.json();

        activeHttpRequests.current = activeHttpRequests.current.filter(
          reqCtrl => reqCtrl !== httpAbortCtrl
        );

        if (!response.ok) {

          if(!responseData.message){
            if(response.status === 401){

              const res = await fetch(process.env.REACT_APP_API_URL + '/auth/admin/refresh', {
                method: 'post',
                body: JSON.stringify({ refresh_token: auth.refresh_token }),
                headers,
                signal: httpAbortCtrl.signal
              });

              if(res.status === 200 || res.status === 201){
                const responseData = await res.json();
                // auth.updateToken(responseData.data.access_token)
                auth.login(responseData.data.user._id, responseData.data.user, responseData.data.token, responseData.data.refresh_token);
              }else{
                auth.logout();
                navigate("/login");
              }
            }
            if(response.status === 404){
              responseData.message = "Not Found";
            }
            if(response.status === 403){
              if(responseData.verified === false){
                responseData.message = "Not Verified";
              }else{
                responseData.message = "Forbidden";
              }
            }
          }
          throw new Error(responseData.message);
        }

        setIsLoading(false);
        return responseData;
      } catch (err) {
        //todo write to logs
        setError(err.message);
        setTimeout(()=>{
          setError("");
        }, 3000);
        setIsLoading(false);
        throw err;
      }
    },
    []
  );

  const clearError = () => {
    setError(null);
  };

  useEffect(() => {
    return () => {
      // eslint-disable-next-line
      activeHttpRequests.current.forEach(abortCtrl => abortCtrl.abort());
    };
  }, []);

  return { isLoading, error, sendRequest, clearError };
};
