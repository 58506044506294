import React, {useEffect, useState} from 'react';
import Layout from "../../layouts/Layout";
import {documentsApi} from "./api/api";
import {usePagination} from "../../hooks/pagination-hook";
import variables from "../../constants/variables";
import LoadingPageSpinner from "../../components/Loading/LoadingPageSpinner";
import Pagination from "../../components/Pagination/Pagination";
import DocumentWidget from "../../components/Documents/DocumentWidget";
import DocumentHeader from "../../components/Documents/DocumentHeader";
import {useParams} from "react-router-dom";
import Back from "../../components/Button/Back";
import Alert from "../../components/Alert/Alert";
// import {useGetSubClients} from "../../hooks/subclients-hook";
import {useNavigate} from "react-router-dom";
import {t} from "i18next";

const AssignDocument = () => {
    const [subClientId, setSubClientId] = useState(useParams().subclient_id);
    const userId = useParams().patient_id;
    if(!subClientId || !userId){
        return 404;
    }
    const { getDocumentsRequest, downloadDocumentRequest, assignDocument, isLoading,  error, clearError } = documentsApi();
    const {currentPage, setCurrentPage, pageCount, setCountHandler} = usePagination();
    const [loadedDocuments, setLoadedDocuments] = useState();
    const [search, setSearch] = useState();
    const [updated, setUpdated] = useState(false);
    const [message, setMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const {subClients} = useGetSubClients();
    const navigate = useNavigate();


    useEffect(() => {
        const fetchDocuments = async () => {
            try {
                const responseData = await getDocumentsRequest(currentPage, search, subClientId);
                const items = responseData.data;
                // if(items && items.length){
                    setLoadedDocuments(items);
                // }
                setCountHandler(responseData.total, variables.maxDocumentsPagination);
            } catch (err) {
                console.log(err);
            }
        };
        fetchDocuments();
    }, [currentPage, search, updated]);

    const downloadHandler = async (_id) => {

        try{
            const response = await downloadDocumentRequest(_id);
            const url = response.data;
            window.open(
                url,
                '_blank'
            );
        }catch (e){}

    }
    const assignDocuments = async () => {
        let documentsArray = [];
        const docs = document.querySelectorAll('.assigned');
        docs.forEach(item=>{
            console.log(item);
            if(item.dataset.doc_id){
                documentsArray.push(item.dataset.doc_id);
            }

        });
        if(documentsArray.length){
            try {
                const data = {"subclient_id": subClientId, "document_ids": documentsArray, "description": "Document description"};
                const responseData = await assignDocument(JSON.stringify(data));
                if(responseData.success){
                    setMessage("Success!");
                    setTimeout(()=>{
                        setMessage("");
                    }, 2000);
                    // docs.forEach(item=>{
                    //     item.click();
                    // });
                    navigate(-1);
                }
            } catch (err) {
                console.log(err);
            }
        }else{
            setErrorMessage(t('SelectAssignDocuments'));
            setTimeout(()=>{
                setErrorMessage("");
            }, 2000);
        }
    }

    return (
        <Layout>
            {isLoading && <LoadingPageSpinner />}
            <Back/>
            <div className="absolute z-50 flex-center w-full">
                <Alert error={errorMessage || error} message={message}/>
            </div>
            {loadedDocuments && (
                <div className="p-4">
                    <DocumentHeader subClientId={subClientId} subClients={subClients} setSubClientId={setSubClientId} action_text={t("AssignDocuments")} searchHandler={setSearch} btn_action={assignDocuments}/>
                    <Pagination pageCount={pageCount} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
                    <DocumentWidget select={true} downloadHandler={downloadHandler} items={loadedDocuments}/>
                </div>
            )}
        </Layout>);
};
export default AssignDocument;
