import AddButton from "../Button/AddButton";
import React, {useState} from "react";
import {t, use} from "i18next";
import EditButton from "../Button/EditButton";
import SurveyComments from "./Comments/SurveyComments";

const Survey = (props) => {
    const loadedSurvey = props.loadedSurvey;
    const [editMode, setEditMode] = useState(false);
    const editButtonHandler = () =>{
        setEditMode(true);
    }

    const submitSurveyHandler = async () => {
        const answers = [];
        let error = false;
        const surveyForm = document.querySelector('.survey-form');
        if(surveyForm){
            const sections = surveyForm.querySelectorAll(".answer-section");

            if(sections){
                sections.forEach(section=>{

                    const section_id = section.dataset.section_id;

                    let sectionObj = {"section_id": section_id};
                    sectionObj.questions = [];

                    const freeAnswers = section.querySelectorAll(".question-block");
                    if(loadedSurvey && loadedSurvey.survey_info.type === "free"){
                        if(freeAnswers) {
                            freeAnswers.forEach(item => {
                               let answerIn =  item.querySelector(".free-answer");
                               if(answerIn && answerIn.value){
                                   let questionObj = {};
                                   questionObj.question_id = answerIn.dataset.q_id;
                                   questionObj.other_text = answerIn.value;
                                   sectionObj.questions.push(questionObj);
                               }
                            });
                        }
                    }

                    const blocks = section.querySelectorAll(".answer-block");
                    let otherAnswer = "";
                    if(blocks){
                        blocks.forEach(item=>{
                            const question_id = item.dataset.question_id;

                            let questionObj = {};
                            questionObj.question_id = question_id;

                            const answerInput = item.querySelector('input.answer_id');
                            const pointInput = item.querySelector('input.point');

                            const questionBlock = item.closest(".question-block");
                            if(questionBlock){
                                const otherAnswerInput = questionBlock.querySelector(".other-answer");
                                if(otherAnswerInput){
                                    otherAnswer = otherAnswerInput.value;
                                }
                            }


                            if(answerInput && pointInput){
                                const answer_id = answerInput.value;

                                    if(loadedSurvey && loadedSurvey.survey_info.type === "multi-free"){
                                        if(answer_id){
                                            let arr = answer_id.split(",");
                                            arr.forEach((item)=>{
                                                let newQuestionObj = {};
                                                newQuestionObj.question_id = questionObj.question_id;
                                                newQuestionObj.probability_id = item;
                                                sectionObj.questions.push(newQuestionObj);
                                            })
                                        }
                                        if(otherAnswer){
                                            questionObj.probability_id = "";
                                            questionObj.other_text = otherAnswer;
                                            sectionObj.questions.push(questionObj);
                                        }

                                    }else{
                                        if(answer_id) {
                                            questionObj.probability_id = answer_id;
                                            if (otherAnswer) {
                                                questionObj.other_text = otherAnswer;
                                            }
                                            sectionObj.questions.push(questionObj);
                                        }
                                    }

                                    if(!answer_id){
                                        error = true;
                                    }
                            }
                        });

                    }
                    answers.push(sectionObj);
                });
                // if(!error){
                    await props.submitSurvey(answers);
                    setEditMode(false);
                // }else{
                //     props.setErrorMessage(t("AnswerAllQuestions"));
                //     setTimeout(()=>{
                //         props.setErrorMessage("");
                //     }, 2000);
                // }
            }
        }
    }


    const setAnswer = (e, answerID, scoreValue) => {
        if(editMode) {
            const parent = e.target.closest('.probabilities');
            if (parent) {
                const points = parent.querySelectorAll('.answer-point');
                if (points) {
                    if(loadedSurvey && loadedSurvey.survey_info.type === "multi-free"){
                        if(e.target.classList.contains("bg-blue-400")){
                            e.target.classList.remove("bg-blue-400");
                            e.target.classList.add("bg-gray-200");
                        }else{
                            e.target.classList.add("bg-blue-400");
                            e.target.classList.remove("bg-gray-200");
                        }
                    }else{
                        points.forEach(item => {
                            item.classList.remove("bg-blue-400");
                            item.classList.add("bg-gray-200");
                        });
                    }

                }
                let questionBlock = e.target.closest('.question-block');
                const answerInput = questionBlock.querySelector('input.answer_id');
                const pointInput = questionBlock.querySelector('input.point');


                if (answerInput && pointInput) {
                    if(loadedSurvey && loadedSurvey.survey_info.type === "multi-free"){

                        const otherInput = parent.querySelector(".other-answer");

                        const checked_points = parent.querySelectorAll('.answer-point.bg-blue-400');
                        let multiFreeIDs = [];
                        let multiFreePoint = 0;
                        checked_points.forEach((item)=>{
                            multiFreeIDs.push(item.dataset.id);
                            multiFreePoint += parseInt(item.dataset.point);
                        });
                        answerInput.value = multiFreeIDs.length ? multiFreeIDs.join(",") : "";
                        pointInput.value = multiFreePoint;
                        otherInput.readOnly = multiFreeIDs.length

                    }else{
                        answerInput.value = answerID;
                        pointInput.value = scoreValue;
                    }


                }

                // if (parent.parentElement) {
                if (questionBlock) {
                    // var score = parent.parentElement.querySelector('.answer-score');
                    var score = questionBlock.querySelector('.answer-score');
                    if (score) {
                        score.innerHTML = scoreValue;
                    }
                }
                const section = e.target.closest('.answer-section');
                if(section){
                    const questionScores = section.querySelectorAll('.answer-score');
                    let sectionScore = 0;
                    questionScores.forEach(s => {
                        sectionScore += parseInt(s.innerText);
                    });
                    section.querySelector('.section-score').innerHTML = sectionScore;
                }

            }
            if(loadedSurvey && loadedSurvey.survey_info.type !== "multi-free") {
                e.target.classList.add('bg-blue-400');
                e.target.classList.remove('bg-gray-200');
            }
        }
    }

    const multiFreeProbabilities = (arr) =>{
        let answer_id = [];
        arr.forEach((item)=>{
            answer_id.push(item.probability_id);
        })
        return answer_id.join(",");
    }

    const isCheckedMultiFree = (id, arr) => {
        let isChecked = false;
        if(Array.isArray(arr)){
            arr.forEach((item)=>{
                if(item.probability_id === id){
                    console.log(123);
                    isChecked =  true;
                }
            });
        }

        return isChecked;
    }

    // const getOtherValueMultiFree = (arr) => {
    //     let value = "";
    //     if(Array.isArray(arr)){
    //         arr.forEach((item)=>{
    //             if(item.other){
    //                 value =  item.other;
    //             }
    //         });
    //     }
    //
    //     return value;
    // }

    return (
        <div>
            {loadedSurvey && (<div className="flex justify-between xl:items-center items-end border-b p-5 xl:flex-row flex-col">
                <div className="text-gray-600">
                    <div className="xl:text-2xl lg:text-xl text-lg font-bold">{loadedSurvey.survey_info.name}</div>
                    <div className="text-lg">{loadedSurvey.survey_info.description}</div>
                    <div className="text-md my-2">{loadedSurvey.survey_info.author}</div>
                    <div className="text-md">
                        {loadedSurvey.survey_info.extra.split('\\n').map((line, index) => (
                            <React.Fragment key={index}>
                                {" "}
                                {line} <br/>{" "}
                            </React.Fragment>
                        ))}
                    </div>
                </div>
                {<div className="flex-center">
                    {!editMode &&
                        <EditButton onClick={editButtonHandler} className="justify-center 2xl:mr-4"
                                    text={`${loadedSurvey.status_complete ? t("EditAnswers") : t("CompleteTheForm")}`}/>
                    }
                    {editMode &&
                        <>
                            <div onClick={()=>{setEditMode(false)}} className="cursor-pointer text-gray-500 mr-4 text-sm">{t("Cancel")}</div>
                            <AddButton onclick={submitSurveyHandler} withoutIcon={true} btn_text={"Submit survey"}/>
                        </>
                    }
                    {!editMode && <div className="flex-center">
                        <div className="mx-4 font-bold text-white px-3 py-1 bg-add_btn rounded-md">{loadedSurvey.total_score}</div>
                        <div>
                            <div className="text-sm text-gray-500">{t("SurveyStatus")}</div>
                            <div className={`${loadedSurvey.status_complete ? 'text-green-600' : 'text-yellow-600'} font-bold text-sm`}>{loadedSurvey.status_complete ? t("Completed") : t("NotCompleted")}</div>
                        </div>
                    </div>}
                </div>
                }
                {/*<div className="flex-center">*/}
                {/*    <div className="text-gray-400 text-sm mr-4">Export As</div>*/}
                {/*    <div className="text-white px-4 classic-btn text-gray-400 text-sm mr-4">PDF</div>*/}
                {/*    <div className="text-white px-4 classic-btn text-gray-400 text-sm">Excel</div>*/}
                {/*</div>*/}
            </div>)}
            {loadedSurvey && (<div className={`${!props.assign_id ? 'w-full': ''}`}>

                    <div className={` items-start justify-between w-full`}>
                        <div className="p-5 survey-form">
                            {loadedSurvey.sections.map(item=>
                                <div key={item._id} className="answer-section mb-6 shadow rounded-md text-gray-600" data-section_id={item._id}>

                                    <div className="flex xl:items-center justify-between xl:flex-row flex-col items-end">
                                        <div className="text-xl p-4">
                                            {(item.label || item.name) && <div>
                                                <div>{item.label}</div>
                                                <div className="text-xl">
                                                {item.name.split("\\n").map((line, index) => (
                                                    <React.Fragment key={index}>
                                                        {" "}
                                                        {line} <br/>{" "}
                                                    </React.Fragment>
                                                ))}
                                                </div>
                                            </div>}
                                        </div>

                                        <div className="flex items-center justify-end">
                                            <div className="text-sm text-gray-400">{t("SectionScore")}</div>
                                            <div className="m-3 px-3 py-1 text-sm mb-2 rounded-md text-white font-bold bg-blue-400">
                                                {editMode && <div className="section-score">{item.total_per_section ? item.total_per_section : 0}</div>}
                                                <div>{!editMode && <div>{item.total_per_section ? item.total_per_section : 0}</div>}</div>
                                            </div>
                                        </div>
                                    </div>

                                    {item.questions.map((q,key) =>
                                        <div className={`question-block flex flex-wrap justify-between items-center p-3
                                    ${item.questions.length -1 === key ? '' : 'border-b' }`} key={(Math.random() + 1).toString(36).substring(7)}>


                                            {/*<div className="lg:w-2/6 py-4">*/}
                                            <div className="lg:w-full py-4">
                                                <div className="flex justify-start items-center mb-2">
                                                    <div className="text-right mr-3 min-w-max">{q.label}.</div>
                                                     <div>{q.description}</div>
                                                </div>
                                                {(loadedSurvey.survey_info.type === "multi" || loadedSurvey.survey_info.type === "multi-single" || loadedSurvey.survey_info.type === "multi-free")
                                                    && (<div className="probabilities">
                                                    {q.probabilities.map((p,k) =>
                                                        <div key={k} className="flex items-center justify-start mb-4">
                                                            <div style={{"minWidth": "12px", "height": "12px"}}
                                                                 data-point={p.point}
                                                                 data-id={p._id}
                                                                 onClick={(e)=>{setAnswer(e, p._id, p.point)}}
                                                                 className={`${loadedSurvey.survey_info.type === "multi-free" ? (isCheckedMultiFree(p._id, q.probability_id) ? 'bg-blue-400' : 'bg-gray-200') : (q.probability_id && q.probability_id === p._id ? 'bg-blue-400' : 'bg-gray-200') } mr-2 answer-point rounded-full ${editMode ? "cursor-pointer border border-gray-500" : ""}`}>
                                                            </div>
                                                            <div>{p.label}</div>
                                                            <div>{p.description}</div>
                                                        </div>
                                                    )}
                                                    {loadedSurvey.survey_info.type === "multi-free" && (
                                                        <div className={`mt-4 text-sm`}>
                                                            {q.other && (<><div className="mb-2">{t("Other")}:</div>
                                                                <input readOnly={!editMode} defaultValue={q.other_text ?? ''} placeholder="Write here" className={`classic-input other-answer`}/></>)}
                                                            {/*<input readOnly={!editMode || (q.probability_id && q.probability_id.length)} defaultValue={q.other} placeholder="Write here" className={`classic-input other-answer`}/>*/}
                                                        </div>)}
                                                </div>)}
                                                {loadedSurvey.survey_info.type === "free" && (
                                                    <div className={`mt-4 text-sm`}>
                                                        <div className="mb-2">{t("Other")}:</div>
                                                        <input readOnly={!editMode} placeholder="Write here" data-q_id={q._id} defaultValue={q.other_text ?? ''} className={`classic-input free-answer`}/>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="w-full answer-block" data-question_id={q._id}>
                                            {/*<div className="lg:w-4/6 answer-block" data-question_id={q._id}>*/}
                                                <div className="flex items-center justify-end">
                                                    <div className="text-sm text-gray-400 mr-2">{t("Score")}</div>
                                                    <div className="px-3 py-1 text-sm mb-2 rounded-md text-white font-bold bg-add_btn">
                                                        {editMode && <div className="answer-score">{q.probability_id ? q.point : 0}</div>}
                                                        <div>{!editMode && <div>{q.probability_id ? q.point : 0}</div>}</div>
                                                    </div>
                                                </div>
                                                {/*<div className={`${loadedSurvey.survey_info.type ==='multi' ? 'hidden': 'justify-end'} flex items-center text-sm probabilities`}>*/}
                                                <div className={`${loadedSurvey.survey_info.type ==='multi' ? 'hidden': 'justify-center'} md:flex items-center text-sm probabilities`}>
                                                    {loadedSurvey.probabilities.map((p,k) =>
                                                        <div key={k} className="ml-3 my-1 flex items-center justify-start md:justify-end">
                                                            <div style={{"width": "12px", "height": "12px"}}
                                                                 onClick={(e)=>{setAnswer(e, p._id, p.point)}}
                                                                 className={`${q.probability_id && q.probability_id === p._id ? 'bg-blue-400' : 'bg-gray-200'}  
                                                             mr-2 answer-point rounded-full ${editMode ? "cursor-pointer border border-gray-500" : ""}`}>
                                                            </div>
                                                            <div>
                                                                <div className="mb-2-2">{p.label}</div>
                                                                <div>{p.description}</div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                {loadedSurvey.survey_info.type === "multi-free" && <input type="hidden" className="answer_id" value={q.probability_id ? multiFreeProbabilities(q.probability_id) : ''}/>}
                                                {loadedSurvey.survey_info.type !== "multi-free" && <input type="hidden" className="answer_id" value={q.probability_id ? q.probability_id : ''}/>}
                                                <input type="hidden" className="point" value={q.probability_id ? q.point : ''}/>
                                            </div>

                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
export default Survey;
