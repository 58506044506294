import React from "react";
import {helpers} from "../../utils/helpers/helpers";
import {t} from "i18next";

const DocumentWidget = (props) => {
    const changeColor = (e)=>{

        if(props.select){
            let select = e.target.querySelector(".item-select");
            if(select){
                select.classList.toggle('bg-gray-300');
                select.classList.toggle('bg-blue-400');
                select.classList.toggle('assigned');
                const item = e.target.closest('.document-item');
                if(item){
                    item.classList.toggle('border-gray-300');
                    item.classList.toggle('border-blue-400');
                }
            }

        }
    }
    const {transformDate} = helpers();
    return (
        <>
            {props.items.map(item =>
                <div key={item._id} onClick={(e)=>{changeColor(e)}} className={`${props.select ? 'cursor-pointer' : ''} document-item my-2 bg-card_bg rounded-md p-4 text-sm ${props.select ? 'border border-gray-300' : ''}`}>
                    <div className="flex items-center justify-between">
                        <div className="flex-center">
                            <div className={`${props.select ? "block" : "hidden"}`}>
                                <div style={{"width": "17px", "height": "17px"}}
                                     className="item-select mr-4 rounded-full bg-gray-300 cursor-pointer" data-doc_id={item._id}>
                                </div>
                            </div>
                            <div>
                                <div className="text-sm text-red-400">{t("PDF_File")}</div>
                                <div className="text-gray-600 font-bold lg:text-base text-sm mr-1">{item.name}</div>
                                {!item.name && <div className="text-gray-600 font-bold lg:text-base text-sm mr-1">{item.document_id ? item.document_id.name : "no name"}</div>}
                                <div className="text-gray-600 font-bold lg:text-base text-sm mr-1">{item.description}</div>
                                {/*<div className="text-gray-600 font-bold lg:text-base text-sm mr-1">{item._id}</div>*/}
                            </div>
                        </div>

                        <div className="w-2/10 flex-center">
                            {/*{props.deleteButtonHandler && <button onClick={(event)=>{event.preventDefault(); props.deleteButtonHandler(item._id);}}*/}
                            {/*        type="text" className="flex items-center justify-center mr-4 text-add_btn">*/}
                            {/*    <img className="w-4 mr-2" src="/icons/remove-circle-orange.svg" alt=""/>*/}
                            {/*    {t("RemoveDocument")}*/}
                            {/*</button>}*/}
                            {props.editDocumentHandler && <button onClick={()=>{ props.editDocumentHandler(item._id, item.description);}}
                                                           type="text" className="flex items-center justify-center mr-4 text-blue-400">
                                <img className="w-3 mr-2 ml-4" src="/icons/edit.svg" alt=""/>
                                {t("EditDescription")}
                            </button>}
                            {item.assigned && <div className="mr-5 text-sm text-white py-1 px-5 rounded-3xl bg-blue-500">{t("AlreadyAssigned")}</div>}
                            <img onClick={()=>props.downloadHandler(item.document_id ? item.document_id._id : item._id)} style={{"transform": "rotate(90deg)"}} className="mr-4 cursor-pointer" src="/icons/arrow-right.svg" alt=""/>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default DocumentWidget;
